<template>
  <div class="vx-row" style="margin-top: 10px">
    <div class="vx-col md:w-1/1 w-full" style="margin-bottom: 160px;padding-top: 10px;">
      <div class="vx-row mb-6 ml-4 mr-4" style="z-index: 99999 !important">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Company</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <formInputs :components="this.formInput.inputs" />
        </div>
      </div>
      <div class="vx-row mb-6 ml-4 mr-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Operating Unit</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <formInputs
            :components="this.formOu.inputs"
            @handlerSearch="handlerSearchMS"
            @update-forminput="this.updateforminput"
          />
        </div>
      </div>
      <div class="vx-row mb-6 ml-4 mr-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Territory</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <formInputs :components="this.formTerritory.inputs" />
        </div>
      </div>
      
      <div class="vx-row mb-6 ml-4 mr-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Tax Invoice Period</span>
        </div>
        <div class="vx-col sm:w-1/5 w-full">
          <formInputs :components="this.formMonth.inputs" />
        </div>
        <div class="vx-col sm:w-1/5 w-full">
          <formInputs :components="this.formYear.inputs" />
        </div>
      </div>
      <div class="vx-row mb-6 ml-4 mr-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Tax Type</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <formInputs :components="this.formTaxInvoiceType.inputs" />
        </div>
      </div>
      <div class="vx-row mb-6 ml-4 mr-4">
        <div class="vx-col sm:w-4/5 w-full text-center">
          <vs-button
            class="mr-4"
            @click="handleView()"
            color="primary"
            icon-pack="feather"
            >View
          </vs-button>
          <vs-button
            v-if="tabs == 0"
            :disabled="!this.itemRefs.some((dt) => dt.inputs[1].components.value)"
            @click="handleSubmit()"
            color="danger"
            icon-pack="feather"
            type="border"
            >Generate
          </vs-button>
          <!-- <vs-button
            v-else-if="tabs == 1"
            @click="handleExport()"
            color="danger"
            icon-pack="feather"
            type="border"
            >Export
          </vs-button> -->
        </div>
      </div>
      <vs-row class="mb-6 ml-4 mr-4" vs-w="12">
        <vs-col
          class="mb-3"
          vs-type="flex"
          vs-justify="left"
          vs-align="center"
          vs-w="3"
        >
          <span>Total Invoice : {{ this.table.total }}</span>
        </vs-col>
        <vs-col
          class="mb-3"
          vs-type="flex"
          vs-justify="left"
          vs-align="center"
          vs-w="8"
        >
          <span>Total SN Available : {{ this.total_sn_available }}</span>
        </vs-col>
      </vs-row>
      <vs-divider class="mb-2"></vs-divider>
      <vs-tabs :color="colorx" v-model="tabs">
        <vs-tab
          @click="
            colorx = 'success';
            tabClick(gen);
          "
          label="SN Generate"
        >
          <div class="con-tab-ejemplo">
            <datatable
            :itemRefs="this.itemRefs"
            :tablep="this.table"
            :modelItemRefInput="this.tmpitemRef"
            @change-length ="handleChangelength"
            @search-itemref ="handleSearch"
            @handleChangePage="handleChangePage"
            @update-itemref="updateitemref"
            @input-itemref="oninput"
          />
          </div>
        </vs-tab>
        <vs-tab
          @click="
            colorx = 'warning';
            tabClick(data_gen);
          "
          label="Data Generate"
        >
          <div class="con-tab-ejemplo">
            <vs-row class="mb-6 ml-4 mr-4">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12"
              >
              <datatable
              class="w-full"
                :itemRefs="this.dtGen"
                :tablep="this.table"
                :modelItemRefInput="this.tmpdtGen"
                @change-length ="handleChangelength"
                @update-itemref="updateitemref"
                @handleChangePage="handleChangePage"
                @input-itemref="oninput"
              />
              </vs-col>
            </vs-row>
            
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </div>
</template>

<script>
import datatable from "../datatable.vue";
import formInputs from "../form_inputs.vue";
import moment from "moment";
export default {
  components: {
    datatable,
    formInputs,
  },
  data() {
    var baseUrl = window.location.origin
    return {
      baseUrl:baseUrl,
      selected: {},
      responseData: {},
      users: [],
      type: 0,
      gen:0,
      data_gen:1,
      tab:0,
      colorx:"success",
      id_inv:[],
      data: {
        npwp: "",
      },
      table:{
        start:0,
        end : 0,
        page: 0,
        stripe:false,
        length: 10,
        search: "",
        order: "id",
        sst: true,
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5,10, 25, 50, 100, "All"],
        
      },
      tabs:0,
      formTaxEntity: {
        id: 1,
        inputs: {
          disabled: false,
          validate: "required",
          name: "taxt_entity",
          placeholder: "Type to search",
          type: "multiselect",
          value: {},
          option: [],
          multiple: false,
          allowempty: false,
          track_by: "ID",
          onSelectEvent: (selected) => {
            console.log(selected);
          },
        },
      },
      formOu: {
        id: 2,
        inputs: {
          disabled: false,
          validate: "required",
          name: "operating_unit",
          placeholder: "Type to search",
          type: "multiselect",
          value: {},
          option: [],
          multiple: false,
          allowempty: false,
          internal_search:false,
          clear_on_select:false,
          track_by: "ID",
          onSelectEvent: (selected) => {
            console.log(selected);
          },
        },
      },
      tempDataOu:[],
      formTerritory: {
        id: 3,
        inputs: {
          disabled: false,
          validate: "required",
          name: "territory",
          placeholder: "Type to search",
          type: "multiselect",
          value: {},
          option: [],
          multiple: false,
          allowempty: false,
          track_by: "ID",
          onSelectEvent: (selected) => {
            console.log(selected);
          },
        },
      },
      formTaxInvoiceType: {
        id: 4,
        inputs: {
          disabled: false,
          validate: "required",
          name: "tax_inv_type",
          placeholder: "Type to search",
          type: "multiselect",
          value: {},
          option: [],
          multiple: false,
          allowempty: false,
          track_by: "ID",
          onSelectEvent: (selected) => {
            console.log(selected);
          },
        },
      },
      formInvoiceType: {
        id: 5,
        inputs: {
          disabled: false,
          validate: "required",
          name: "invoice_type",
          placeholder: "Type to search",
          type: "multiselect",
          value: {
            id:0,
            text:"AR Invoice"
          },
          option: [
            {
              id:0,
              text:"AR Invoice"
            },
            {
              id:1,
              text:"Debit Note"
            }
          ],
          multiple: false,
          allowempty: false,
          track_by: "id",
        },
      },
      formInput: {
        id: 6,
        inputs: {
          disabled: false,
          validate: "required",
          name: "tax_entity",
          placeholder: "Tax Entity",
          type: "input",
          value: "",
        },
      },
      formYear: {
        id: 7,
        inputs: this.forminputyear(),
      },
      formMonth: {
        id: 8,
        inputs: this.forminputmonth(),
      },
      itemRefs: [],
      tmpitemRef: {
        id: 2,
        action: false,
        inputs: this.getInputsHead(),
      },
      dtGen: [],
      tmpdtGen: {
        id: 2,
        action: false,
        inputs: this.getInputsHeadDataGen(),
      },
      DataOperatingUnit: [],
      DataTerritory: [],
      data_head: {},
      total_sn_available: 0,
    };
  },
  mounted() {
    this.getData();
    this.getTaxType();
    console.log("Router: ", this.$router);
  },
  computed:{
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  methods: {
    leadingZero(value, fixLength) {
      return "0".repeat(fixLength-value.length)+value
    },
    forminputyear() {
      
      return {
        disabled: false,
        validate: "required",
        name: "year",
        placeholder: "Select Year",
        type: "date",
        min_view:"year",
        format: 'yyyy',
        value: "",
        // option: date_year,
        multiple: false,
        allowempty: false,
        track_by: "ID",
        onSelectEvent: (selected) => {
          console.log(selected);
        },
      };
    },
    handleSearch(search){
      this.table.search = search
      this.handleView()
    },
    handlerSearchMS(comp,search){
      if (comp.name =="operating_unit"){
        console.log("search ou:",search)
        this.formOu.inputs.option =this.tempDataOu.filter(v=>{
          var tempText = v.text.toLowerCase()
          var tempSearch = search.toLowerCase()
          return tempText.search(tempSearch)>=0
        })
      }
      console.log("formOu",this.formOu)
    },
    forminputmonth() {
      
      return {
        disabled: false,
        validate: "required",
        name: "month",
        placeholder: "Select Month",
        type: "date",
        value:"",
        min_view:"month",
        format: 'MM',
        // type: "multiselect",
        // value: value,
        // option: dtselect,
        multiple: false,
        allowempty: false,
        track_by: "ID",
        onSelectEvent: (selected) => {
          console.log(selected);
        },
      };
    },
    handleChangelength(item){
      console.log(item)
      if(item=="All"){
        this.table.end = this.table.total
      }else{
        this.table.end = item
      }
      this.table.length =this.table.end
      this.handleView()
    },
    resetTable(){
      this.table.total =0
      this.table.totalPage =0
      this.table.totalSearch =0
      this.table.length =10
      this.table.start=0
      this.table.end=0
      this.table.stripe =false
    },
    tabClick(i){
      this.resetTable()
      if(i==0){
        this.getDataSN()
      } else {
        this.table.stripe = true
        this.getDatGen()
      }
    },
    oninputSearch(){
      // this.getDatGen()
      if(this.tabs==0){
        this.getDataSN()
      } else {
        this.getDatGen()
      }
    },
    oninput(data, type) {
      console.log(data);
      if (type == "itemref") {
        var numb = data.value
        console.log("numb",numb)
        if (data.name == "start_number") {
          // this.itemRefs[data.id].inputs[4].components.value = numb
          // total_sn =
          //   this.itemRefs[data.id].inputs[5].components.value - numb;
          // if (total_sn < 0) {
          //   this.itemRefs[data.id].inputs[6].components.value = "" + 0;
          //   this.itemRefs[data.id].inputs[7].components.value = "" + 0;
          //   this.itemRefs[data.id].inputs[8].components.value = "" + 0;
          // } else {
          //   this.itemRefs[data.id].inputs[6].components.value = "" + total_sn;
          //   this.itemRefs[data.id].inputs[7].components.value = "" + total_sn;
          //   this.itemRefs[data.id].inputs[8].components.value = "" + total_sn;
          // }
        } else if (data.name == "end_number") {
          // this.itemRefs[data.id].inputs[5].components.value = numb
          // total_sn =
          //   numb - this.itemRefs[data.id].inputs[4].components.value;
          // if (total_sn < 0) {
          //   this.itemRefs[data.id].inputs[6].components.value = "" + 0;
          //   this.itemRefs[data.id].inputs[7].components.value = "" + 0;
          //   this.itemRefs[data.id].inputs[8].components.value = "" + 0;
          // } else {
          //   this.itemRefs[data.id].inputs[6].components.value = "" + total_sn;
          //   this.itemRefs[data.id].inputs[7].components.value = "" + total_sn;
          //   this.itemRefs[data.id].inputs[8].components.value = "" + total_sn;
          // }
        } else if (data.name == "checkbox") {
          var id = data.id_invoice
            if (!id) {
              id=0
            }
          if (data.head_val != undefined) {
            this.data_head = data;
            this.id_inv = []
            if (data.head_val == true) {
              if (this.tabs == 0) {
                this.itemRefs.forEach((dt, i) => {
                  if (!this.itemRefs[i].inputs[1].components.disabled) {
                    this.itemRefs[i].inputs[1].components.value = true;
                    this.id_inv.push(this.itemRefs[i].id_invoice)
                  }
                });
              } else if (this.tabs == 1) {
                this.dtGen.forEach((dt, i) => {
                  if (!this.dtGen[i].inputs[1].components.disabled) {
                    this.dtGen[i].inputs[1].components.value = true;
                    this.id_inv.push(this.dtGen[i].id_invoice)
                  }
                });
              }
            } else if (data.head_val == false) {
              if (this.tabs == 0) {
                this.itemRefs.forEach((dt, i) => {
                  if (!this.itemRefs[i].inputs[1].components.disabled) {
                  this.itemRefs[i].inputs[1].components.value = false;
                  }
                });
              } else if (this.tabs == 1) {
                this.dtGen.forEach((dt, i) => {
                  if (!this.dtGen[i].inputs[1].components.disabled) {
                  this.dtGen[i].inputs[1].components.value = false;
                  }
                });
              }
            }
          } else {
            if (data.value) {
              this.id_inv.push(id)
            }else{
              this.id_inv = this.id_inv.filter((v) => {
              return v != id;
            });
            }
            if (data.value == false) {
              if (this.data_head) {
                this.data_head.head_val = false;
              }
            }
          }
        }
      }
    },
    updateitemref(data, status) {
      if (status == "add") {
        let i_new = this.itemRefs.length;
        this.itemRefs.push(data);
        for (let i = 0; i < this.itemRefs[i_new].inputs.length; i++) {
          this.itemRefs[i_new].inputs[i].components.id = i_new;
          if (i != 0 && i != 1 && i != 2) {
            this.itemRefs[i_new].inputs[i].components.value = "";
          } else {
            this.itemRefs[i_new].inputs[i].components.value =
              this.itemRefs[i_new - 1].inputs[i].components.value;
          }
        }
      } else if (status == "remove") {
        this.itemRefs = this.itemRefs.filter((v, i) => {
          return i != data;
        });
      }
    },
    getInputs(data = null, i = 0,no =1) {
      var inputs = [];
      var periode = "";
      var invoice_no = "";
      var customer_code = "";
      var customer_name = "";
      var status_config = "";
      var tax_invoice_type = "";
      var type_inv ="";
      var custRoute =""
      var invRoute =""
      var checkbox = false
      var id_inv =0
      if (data) {
          periode = data.posting_date;
          if (periode) {
            periode = moment(periode).format("YYYY-MM-DD");
          }
          invoice_no = data.code;
          customer_code = data.customer_code;
          customer_name = data.customer_name;
          status_config = data.status_ready;
          type_inv = this.formInvoiceType.inputs.value.text //ar invoice
          custRoute = this.$router.resolve({ 
            name: 'customers-edit',
            params: { id: data.customer_id },
          })
          id_inv = data.id
          // custRoute = this.baseUrl+custRoute.href
          custRoute = custRoute.href
          var temp_id_inv = this.id_inv.filter(v=>{
            return v ==data.id
          })
          if (temp_id_inv.length>0) {
            checkbox = true
          }
          tax_invoice_type = `${data.tax_type_code} ${data.tax_type_name}`;
      }
      // if (this.formTaxInvoiceType) {
      //   tax_invoice_type = this.formTaxInvoiceType.inputs.value.ID;
      // }

      inputs.push({
        components: {
          id: i,
          id_input: 0,
          disabled: true,
          validate: "required",
          name: "no",
          width: 40,
          text_align: "text-center",
          placeholder: "0",
          title: "No",
          type: "text",
          value: "" + no,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 1,
          disabled: status_config.toLowerCase()!="ready".toLowerCase(),
          validate: "required",
          name: "checkbox",
          placeholder: "Checkbox",
          title: "checkbox",
          head: "title", //checkbox/title
          type: "checkbox",
          id_invoice:id_inv,
          value: checkbox,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "invoice_date",
          placeholder: "Invoice Date",
          title: "Invoice Date",
          type: "text",
          value: periode,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 2,
          disabled: false,
          validate: "required",
          name: "invoice_no",
          placeholder: "Invoice No",
          title: "Invoice No",
          target:"_blank",
          href:invRoute,
          type: "text-link",
          value: invoice_no,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 4,
          disabled: true,
          validate: "required",
          name: "tax_invoice_type",
          placeholder: "Tax Invoice Type",
          title: "Tax Invoice Type",
          type: "text",
          value: tax_invoice_type,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 4,
          disabled: true,
          validate: "required",
          name: "invoice_type",
          placeholder: "Invoice Type",
          title: "Invoice Type",
          type: "text",
          value: type_inv,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 5,
          disabled: true,
          validate: "required",
          name: "customer_code",
          placeholder: "Customer Code",
          title: "Customer Code",
          target:"_blank",
          type: "text-link",
          href:custRoute,
          value: customer_code,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 6,
          disabled: true,
          validate: "required",
          name: "customer_name",
          placeholder: "Customer Name",
          title: "Customer Name",
          type: "text",
          value: customer_name,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 7,
          disabled: true,
          validate: "required",
          name: "status",
          placeholder: "Status",
          title: "Status",
          target:"_blank",
          type: status_config=="Ready"?"text":"text-link",
          href:custRoute,
          value: status_config,
        },
      });

      return inputs;
    },
    getInputsDataGen(data = null, i = 0,no =0) {
      var inputs = [];
      var periode = "";
      var invoice_no = "";
      var customer_code = "";
      var customer_name = "";
      var tax_invoice_type = "";
      var tax_number = "";
      // var type_inv ="";
      var checkbox = false
      var id_inv =0
      if (data) {
          periode = data.date;
          // invoice_no = data.invoice_reference_code;
          invoice_no = data.code;
          customer_code = data.customer_code;
          customer_name = data.customer_name;
          // tax_number = this.leadingZero(data.tax_number, 12);
          tax_number = this.leadingZero(data.tax_number, 8);
          // type_inv = "AR Invoice"
          tax_invoice_type = `${data.tax_type_code} ${data.tax_type_name}`;
          id_inv = data.id
          var temp_id_inv = this.id_inv.filter(v=>{
            return v ==data.id
          })
          if (temp_id_inv.length>0) {
            checkbox = true
          }
      }
      // if (this.formTaxInvoiceType) {
      //   tax_invoice_type = this.formTaxInvoiceType.inputs.value.ID;
      // }

      inputs.push({
        components: {
          id: i,
          id_input: 0,
          disabled: true,
          validate: "",
          name: "no",
          width: 40,
          text_align: "text-center",
          placeholder: "0",
          title: "No",
          type: "text",
          value: "" + no,
        },
      });
      // inputs.push({
      //   components: {
      //     id: i,
      //     id_input: 1,
      //     disabled: false,
      //     validate: "required",
      //     name: "checkbox",
      //     placeholder: "Checkbox",
      //     title: "checkbox",
      //     head: "title", //checkbox/title
      //     type: "checkbox",
      //     id_invoice:id_inv,
      //     value: checkbox,
      //   },
      // });
      inputs.push({
        components: {
          id: i,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "tax_number",
          placeholder: "Tax umber",
          title: "Tax Number",
          type: "text",
          value: tax_number,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 3,
          disabled: true,
          validate: "required",
          name: "invoice_date",
          placeholder: "Invoice Date",
          title: "Invoice Date",
          type: "text",
          value: periode,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 4,
          disabled: false,
          validate: "required",
          name: "invoice_no",
          placeholder: "Invoice No",
          title: "Invoice No",
          type: "text",
          value: invoice_no,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 5,
          disabled: true,
          validate: "required",
          name: "tax_invoice_type",
          placeholder: "Tax Invoice Type",
          title: "Tax Invoice Type",
          type: "text",
          value: tax_invoice_type,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 6,
          disabled: true,
          validate: "required",
          name: "customer_code",
          placeholder: "Customer Code",
          title: "Customer Code",
          type: "text",
          value: customer_code,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 7,
          disabled: true,
          validate: "required",
          name: "customer_name",
          placeholder: "Customer Name",
          title: "Customer Name",
          type: "text",
          value: customer_name,
        },
      });

      return inputs;
    },
    getTaxType() {
      this.$http
        .get(`api/v1/master/tax-type`)
        .then((resp) => {
          if (resp.code == 500) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else if (resp.code == 200) {
            resp.data.records.sort((a, b) => a.code - b.code)
            this.formTaxInvoiceType.inputs.option = [{ID: 0, text: "ALL"}, ...resp.data.records.map((t) => ({ID: t.ID, text: `${t.code} ${t.name}`}))];
            this.formTaxInvoiceType.inputs.value = {ID: 0, text: "ALL"}
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        });
    },
    getInputsHead() {
      var inputs = [];

      inputs.push({
        components: {
          id: 0,
          id_input: 0,
          disabled: true,
          validate: "required",
          name: "no",
          width: 40,
          text_align: "text-center",
          placeholder: "0",
          title: "No",
          type: "input",
          value: "1",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "checkbox",
          placeholder: "Checkbox",
          title: "checkbox",
          head: "checkbox", //checkbox/title
          head_val: false, //for head=checkbox
          type: "checkbox",
          value: false,
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 1,
          disabled: true,
          validate: "required",
          name: "invoice_date",
          placeholder: "Invoice Date",
          title: "Invoice Date",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 2,
          disabled: false,
          validate: "required",
          name: "invoice_no",
          placeholder: "Invoice No",
          title: "Invoice No",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 4,
          disabled: true,
          validate: "required",
          name: "tax_invoice_type",
          placeholder: "Tax Invoice Type",
          title: "Tax Invoice Type",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 4,
          disabled: true,
          validate: "required",
          name: "invoice_type",
          placeholder: "Invoice Type",
          title: "Invoice Type",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 5,
          disabled: true,
          validate: "required",
          name: "customer_code",
          placeholder: "Customer Code",
          title: "Customer Code",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 6,
          disabled: true,
          validate: "required",
          name: "customer_name",
          placeholder: "Customer Name",
          title: "Customer Name",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 7,
          disabled: true,
          validate: "required",
          name: "status",
          placeholder: "Status",
          title: "Status",
          type: "input",
          value: "",
        },
      });

      return inputs;
    },
    getInputsHeadDataGen() {
      var inputs = [];

      inputs.push({
        components: {
          id: 0,
          id_input: 0,
          disabled: true,
          validate: "required",
          name: "no",
          width: 40,
          text_align: "text-center",
          placeholder: "0",
          title: "No",
          type: "input",
          value: "",
        },
      });
      // inputs.push({
      //   components: {
      //     id: 0,
      //     id_input: 1,
      //     disabled: false,
      //     validate: "required",
      //     name: "checkbox",
      //     placeholder: "Checkbox",
      //     title: "checkbox",
      //     head: "checkbox", //checkbox/title
      //     head_val: false, //for head=checkbox
      //     type: "checkbox",
      //     value: false,
      //     // name: "checkbox",
      //     // placeholder: "Checkbox",
      //     // title: "checkbox",
      //     // head: "checkbox", //checkbox/title
      //     // head_val: false, //for head=checkbox
      //     // type: "checkbox",
      //     // value: false,
      //   },
      // });
      inputs.push({
        components: {
          id: 0,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "tax_number",
          placeholder: "Tax Number",
          title: "Tax Number",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 3,
          disabled: true,
          validate: "required",
          name: "invoice_date",
          placeholder: "Invoice Date",
          title: "Invoice Date",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 4,
          disabled: false,
          validate: "required",
          name: "invoice_no",
          placeholder: "Invoice No",
          title: "Invoice No",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 5,
          disabled: true,
          validate: "required",
          name: "tax_invoice_type",
          placeholder: "Tax Invoice Type",
          title: "Tax Invoice Type",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 6,
          disabled: true,
          validate: "required",
          name: "customer_code",
          placeholder: "Customer Code",
          title: "Customer Code",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 7,
          disabled: true,
          validate: "required",
          name: "customer_name",
          placeholder: "Customer Name",
          title: "Customer Name",
          type: "input",
          value: "",
        },
      });

      return inputs;
    },
    getIDItemRef() {
      return 0;
    },
    // data-taxallocation
    getDataTaxAllocation() {
      const params = {
        operating_unit_id: this.DataOperatingUnit[this.formOu.inputs.value.ID]
          ? this.DataOperatingUnit[this.formOu.inputs.value.ID].ID
          : 0,
      };
      this.$http
        .get(`api/v1/master/tax-invoice/data-taxallocation`, { params })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 500) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else if (resp.code == 200) {
            this.total_sn_available = resp.data.count;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log("error boss:", err);
        });
    },
    handleChangePage(page) {
      // if (this.tab==this.gen){
      //   this.table.page = page;
      //   this.handleView()
      // }
      this.table.page = page;
      if(this.tabs==0){
        this.getDataSN()
      } else {
        this.getDatGen()
      }
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    getDataSN() {
      if (this.DataTerritory[this.formTerritory.inputs.value.ID]) {
        this.$vs.loading();
        var month ="00"
        var year ="2022"
        if (this.formMonth.inputs.value) {
          month =this.formMonth.inputs.value.getMonth()
        }
        if (this.formYear.inputs.value) {
          year =this.formYear.inputs.value.getFullYear()
        }

        const params = {
          length: this.table.length,
          page: this.table.page,
          search: this.table.search,
          order: this.table.order,
          sort: this.table.sort,
          invoice_type: this.formInvoiceType.inputs.value.id,// ar_invoice=>0
          territory_id:
            this.DataTerritory[this.formTerritory.inputs.value.ID].TerrID,
          tax_periode: year + "-" + ((month + 1)<10?"0"+(month + 1):""+(month + 1)),
          tax_type: this.formTaxInvoiceType.inputs.value.ID,
        };
        this.$http
          .get(`api/v1/master/tax-invoice/view-invoice`, { params })
          .then((resp) => {
            console.log(resp)
            if (resp.code == 500) {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else if (resp.code == 200) {
              var dataInvoice = [];
              this.table.total = resp.data.total_record
              this.table.totalPage = resp.data.total_page;
              this.table.totalSearch = resp.data.total_record_search;
              this.table.length = resp.data.total_record_per_page;
              this.setStartEnd()
              var no = this.table.start
              resp.data.records.forEach((dt, i) => {
                // if (i < 1000) {
                  let id = 0
                    id = dt.id
                  dataInvoice.push({
                    id: i,
                    id_invoice: dt.id,
                    action: false,
                    inputs: this.getInputs(dt, i,no),
                  });
                  no++
                // }
              });
              
              
              this.itemRefs = dataInvoice;
              if (this.data_head) {
                this.data_head.head_val = false;
              }
              this.getDataTaxAllocation();
            }
            this.$vs.loading.close();
          })
          .catch((err) => {
            this.$vs.loading.close();
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: err,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            console.log("error boss:", err);
          });
      }
    },
    handleView() {
      if (this.tabs == 0) {
        this.getDataSN()
      } else {
        this.getDatGen()
      }
    },
    getDatGen() {
      var terr_id =0
      if(this.formTerritory.inputs.value){
        terr_id = this.formTerritory.inputs.value.ID
      }
      if (this.DataTerritory[terr_id]) {
        this.$vs.loading();
        var month ="00"
        var year ="2022"
        if (this.formMonth.inputs.value) {
          month =this.formMonth.inputs.value.getMonth()
        }
        if (this.formYear.inputs.value) {
          year =this.formYear.inputs.value.getFullYear()
        }

        const params = {
          length: this.table.length,
          page: this.table.page,
          search: this.table.search,
          order: this.table.order,
          sort: this.table.sort,
          territory_id:
            this.DataTerritory[this.formTerritory.inputs.value.ID].TerrID,
          tax_periode: year + "-" + ((month + 1)<10?"0"+(month + 1):""+(month + 1)),
          tax_type: this.formTaxInvoiceType.inputs.value.ID,
          invoice_type:this.formInvoiceType.inputs.value.text,//ar invoice
        };
        this.$http
          .get(`api/v1/master/tax-invoice/data-generate`, { params })
          .then((resp) => {
            if (resp.code == 500) {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else if (resp.code == 200) {
              var dataInvoice = [];
              this.table.total = resp.data.total_record
              this.table.totalPage = resp.data.total_page;
              this.table.totalSearch = resp.data.total_record_search;
              this.table.length = resp.data.total_record_per_page;
              this.setStartEnd()
              var no = this.table.start
              resp.data.dataInvoice.forEach((dt, i) => {
                if (i < 1000) {
                  let id = 0
                    id = dt.id
                  
                  dataInvoice.push({
                    id: i,
                    id_invoice: id,
                    action: false,
                    inputs: this.getInputsDataGen(dt, i,no),
                  });
                }
                no++
              });
              this.dtGen = dataInvoice;
              if (this.data_head) {
                this.data_head.head_val = false;
              }
              this.getDataTaxAllocation();
            }
            this.$vs.loading.close();
          })
          .catch((err) => {
            this.$vs.loading.close();
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: err,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            console.log("error boss:", err);
          });
      }
    },
    handleSubmit() {
      this.$vs.loading();
      var month ="00"
        var year ="2022"
        if (this.formMonth.inputs.value) {
          month =this.formMonth.inputs.value.getMonth()
        }
        if (this.formYear.inputs.value) {
          year =this.formYear.inputs.value.getFullYear()
        }

      // var data = this.itemRefs.filter((dt) => {
      //   return dt.inputs[1].components.value;
      // });
      var dt = this.itemRefs.filter((c) => c.inputs[1].components.value).map((m) => ({ id: m.inputs[1].components.id_invoice }))
      // console.log(data);
      var tax_invoice_type = "";
      if (this.formTaxInvoiceType) {
        tax_invoice_type = this.formTaxInvoiceType.inputs.value.ID;
      }
      var territory_id=0
      if (this.formTerritory.inputs.value) {
        territory_id = this.DataTerritory[this.formTerritory.inputs.value.ID]
          ? this.DataTerritory[this.formTerritory.inputs.value.ID].TerrID
          : 0
      }else{
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "Territory is required",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
      }
      const params = {
        operating_unit_id: this.DataOperatingUnit[this.formOu.inputs.value.ID]
          ? this.DataOperatingUnit[this.formOu.inputs.value.ID].ID
          : 0,
        territory_id: this.DataTerritory[this.formTerritory.inputs.value.ID]
          ? this.DataTerritory[this.formTerritory.inputs.value.ID].TerrID
          : 0,
        tax_periode: year + "-" + ((month + 1)<10?"0"+(month + 1):""+(month + 1)),
        invoice_ids: this.id_inv,
        data: dt,
        tax_invoice_type: tax_invoice_type,
        invoice_type: this.formInvoiceType.inputs.value.text,//ar invoice
      };
      console.log("params:",params)
      this.$vs.loading();
      this.$http
        .post(`api/v1/master/tax-invoice/generate`, params)
        .then((resp) => {
          this.$vs.loading.close();
          console.log(resp);
          if (resp.code == 500) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            if (resp.data){
              this.id_inv =[]
              resp.data.forEach((v)=>{
                this.id_inv.push(v)
              })
              this.handleView()
            }

          } else if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Data Saved",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.handleView();
            this.getDataTaxAllocation();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log("error boss:", err);
        });
    },
    handleExport() {
      this.$vs.loading();
      var month ="00"
        var year ="2022"
        if (this.formMonth.inputs.value) {
          month =this.formMonth.inputs.value.getMonth()
        }
        if (this.formYear.inputs.value) {
          year =this.formYear.inputs.value.getFullYear()
        }
      var data_id = "";
      var item = this.dtGen.filter((dt) => {
        return dt.inputs[1].components.value;
      });

      item.forEach((dt, i) => {
        if (dt.inputs[1].components.value) {
          data_id += dt.id_invoice;
          if (i < item.length - 1) {
            data_id += ",";
          }
        }
      });
      var tax_invoice_type = "";
      if (this.formTaxInvoiceType) {
        tax_invoice_type = this.formTaxInvoiceType.inputs.value.text;
      }
      const params = {
        params: {
          operating_unit_id: this.DataOperatingUnit[this.formOu.inputs.value.ID]
            ? this.DataOperatingUnit[this.formOu.inputs.value.ID].ID
            : 0,
          territory_id: this.DataTerritory[this.formTerritory.inputs.value.ID]
            ? this.DataTerritory[this.formTerritory.inputs.value.ID].TerrID
            : 0,
          tax_periode: year + "-" + ((month + 1)<10?"0"+(month + 1):""+(month + 1)),
          tax_invoice_type: tax_invoice_type,
          type_exp: "csv",
          invoice_type: this.formInvoiceType.inputs.value.id,//arinvoice=>0
          tax_type: this.formTaxInvoiceType.inputs.value.ID,
          data_id: data_id,
        },
      };
      this.$http
        .get(`api/v1/master/tax-invoice/export`, params)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.handleView();
            this.getDataTaxAllocation();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        });
    },
    handleEdit() {},
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getData() {
      this.$vs.loading();
      // const params = {
      //   id: this.create.id,
      // }
      // console.log(this.formInput)
      this.$http
        .get(`api/v1/master/tax-invoice`)
        .then((resp) => {
          // console.log(this.option.event, 'option')
          this.$vs.loading.close();
          if (resp.code == 500) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else if (resp.code == 200) {
            this.formInput.inputs.value = resp.data.company;
            this.formInput.inputs.disabled = true;
            console.log(this.formInput);
            var dtou = resp.data.dtOu;
            this.DataOperatingUnit = resp.data.dtOu;
            if (dtou) {
              let dataSelect = [];
              dtou.forEach((dt, i) => {
                dataSelect.push({
                  ID: i,
                  text: dt.Code + " - " + dt.Name,
                });
              });
              this.formOu.inputs.value = dataSelect[0];
              this.getDataTerritory(this.formOu.inputs.value);
              this.tempDataOu = dataSelect
              this.formOu.inputs.option = dataSelect;
            }
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log("error boss:", err);
        });
    },
    updateforminput(selected, component) {
      if (component.name == "operating_unit") {
        this.getDataTerritory(selected);
      } else if (component.name == "allocation_type") {
        this.automateInputTaxNumber(selected);
      }
    },
    getDataTerritory(selected = null) {
      this.$vs.loading();
      if (selected) {
        const params = {
          id_ou: this.DataOperatingUnit[selected.ID].ID,
        };
        // console.log(this.formInput)
        this.$http
          .get(`api/v1/master/tax-invoice/territory`, { params })
          .then((resp) => {
            console.log("getDataTerritory", resp.data.dataTerr);
            // console.log(this.option.event, 'option')
            this.$vs.loading.close();
            if (resp.code == 500) {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else if (resp.code == 200) {
              var dataSelect = [];
              if (resp.data.dataTerr) {
                this.DataTerritory = resp.data.dataTerr;
                this.DataTerritory.forEach((dt, i) => {
                  dataSelect.push({
                    ID: i,
                    text: dt.Code + " - " + dt.Name,
                  });
                });
              }
              this.formTerritory.inputs.value = dataSelect[0];
              this.formTerritory.inputs.option = dataSelect;
            }
          })
          .catch((err) => {
            this.$vs.loading.close();
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: err,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            console.log("error boss:", err);
          });
      }
    },
  },
};
</script>