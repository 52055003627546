<template>
  <div class="vx-row">
    <div class="vx-col md:w-1/1 w-full">
      <vx-card title="Form Tax Invoice">
        <div class="vx-row mb-6 ml-4 mr-4" style="z-index: 99999 !important">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Tax Entity</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <formInputs :components="this.formInputTaxEntity.inputs" />
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>NPWP</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <formInputs :components="this.formInputNpwp.inputs" />
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Address</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <formInputs :components="this.formInputAddress.inputs" />
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Year</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <formInputs :components="this.formInputYear.inputs"/>
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4" v-show="tabs == 0">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Sequence</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <formInputs
              :components="this.formTaxReference.inputs"
              @handlerSearch="handlerSearchMS"
              @update-forminput="this.updateforminput"
            />
          </div>
        </div>
        <!-- <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Year</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <formInputs :components="this.formInputYear.inputs" />
            : {{ this.formInputYear.inputs.value }}
            if (this.tab)
          </div>
          if (this.tab)
        </div> -->
        <div class="vx-row mb-6 ml-4 mr-4" v-show="tabs == 0">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Total SN</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <!-- <formInputs :components="this.formInputTotalSn.inputs" /> -->
            : {{ this.formInputTotalSn.inputs.value }}
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4" v-show="tabs == 0">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Total SN Allocated</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <!-- <formInputs :components="this.formInputTotalSnUsed.inputs" /> -->
            : {{ this.formInputTotalSnUsed.inputs.value }}
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4" v-show="tabs == 0">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Total SN Unallocated</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <!-- <formInputs :components="this.formInputTotalSnRemaining.inputs" /> -->
            : {{ this.formInputTotalSnRemaining.inputs.value }}
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4" v-show="tabs == 0">
          <div class="vx-col sm:w-1/5 w-full">
            <span>From</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <!-- <formInputs :components="this.formInputFirstSn.inputs" /> -->
            : {{ this.formInputFirstSn.inputs.value }}
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4" v-show="tabs == 0">
          <div class="vx-col sm:w-1/5 w-full">
            <span>To</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <!-- <formInputs :components="this.formInputLastSn.inputs" /> -->
            : {{ this.formInputLastSn.inputs.value }}
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4" v-show="tabs == 0">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Allocation Type</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <formInputs
              :components="this.formAllocationType.inputs"
              @update-forminput="this.updateforminput"
            />
          </div>
        </div>
        <vs-divider class="mb-2"></vs-divider>
        <vs-tabs :color="colorx" v-model="tabs">
          <vs-tab
            @click="
              colorx = 'success';
              tabClick(tab_alloc);
            "
            label="SN Allocation"
          >
            <div class="con-tab-ejemplo">
              <vs-row class="mb-6 ml-4 mr-4" vs-w="12">
                <!-- <vs-col
                  class="mx-5 mb-3"
                  vs-type="flex"
                  vs-justify="right"
                  vs-align="center"
                  vs-w="12"
                >
                  <vs-input
                    icon="search"
                    placeholder="Search"
                    v-model="search"
                    @input="oninputSearch"
                  />
                </vs-col> -->
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="12"
                >
                  <datatable
                    v-if="renderTable"
                    class="w-full"
                    :itemRefs="this.itemRefs"
                    :tablep="this.table"
                    :modelItemRefInput="this.mdlitemRefs"
                    @search-itemref ="handleSearch"
                    @change-length ="handleChangelength"
                    @handleChangePage="handleChangePage"
                    @input-itemref="oninput"
                  />
                </vs-col>
              </vs-row>
            </div>
          </vs-tab>
          <vs-tab
            @click="
              colorx = 'warning';
              tabClick(tab_data_alloc);
            "
            label="Data Sn Allocation"
          >
            <div class="con-tab-ejemplo">
              <vs-row class="mb-6 ml-4 mr-4" vs-w="12">
                <!-- <vs-col
                  class="mx-5 mb-3"
                  vs-type="flex"
                  vs-justify="right"
                  vs-align="center"
                  vs-w="12"
                >
                  <vs-input
                    icon="search"
                    placeholder="Search"
                    v-model="searchSn"
                    @input="oninputSearchData"
                  />
                </vs-col> -->
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="12"
                >
                  <datatable
                    class="w-full"
                    :itemRefs="this.hisItemRefs"
                    :tablep="this.table"
                    :modelItemRefInput="this.mdlHisitemRefs"
                    @search-itemref ="handleSearch"
                    @change-length ="handleChangelength"
                    @handleChangePage="handleChangePage"
                    @input-itemref="oninput"
                  />
                </vs-col>
              </vs-row>
              
            </div>
          </vs-tab>
        </vs-tabs>

        <!-- div-parameter -->
        <vs-row v-if="tabs == 0" class="mb-3" vs-justify="center">
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="1">
            <vs-button
              @click="handleSubmit()"
              color="primary"
              icon-pack="feather"
              >Submit
            </vs-button>
          </vs-col>
        </vs-row>
      </vx-card>
    </div>
  </div>
</template>

<script>
import datatable from "./datatable.vue";
import formInputs from "./form_inputs.vue";
export default {
  components: {
    datatable,
    formInputs,
  },
  props: {
    id: Number,
  },
  data() {
    return this.initialState();
  },
  watch: {
    "formInputYear.inputs.value": function (val) {
      // console.log("year", val.getFullYear())
      if (this.tab == 0) {
        this.getData()
      } else {
        this.getDataSnAllocation()
      }
    }
  },
  mounted() {
    // console.log("Router: ", this.$router);
    this.getData();
  },
  methods: {
    leadingZero(value, fixLength) {
      return "0".repeat(fixLength-value.toString().length)+value
    },
    initialState() {
      return {
        renderTable: true,
        selected: {},
        temp: {},
        responseData: {},
        users: [],
        type: 0,
        tabI:0,
        tab:0,
        tab_alloc:0,
        tab_data_alloc:1,
        total_sn_perou:0,
        tempTaxNumberAllocated:[],
        msgvalidateFormInputTaxNumberInvalid:"",
        search: "",
        table:{
          start:0,
          stripe:false,
          end : 0,
          page: 0,
          length: 10,
          search: "",
          order: "id",
          sst: true,
          sort: "desc",
          total: 0,
          totalPage: 0,
          totalSearch: 0,
          limits: [5,10, 25, 50, 100, "All"],
          
        },
        searchSn: "",
        data: {
          npwp: "",
        },
        formTaxEntity: {
          id: 1,
          inputs: {
            disabled: false,
            validate: "required",
            name: "tax_entity",
            placeholder: "Type to search",
            type: "multiselect",
            value: {},
            option: [],
            multiple: false,
            allowempty: false,
            track_by: "ID",
            onSelectEvent: (selected) => {
              console.log(selected);
            },
          },
        },
        tempDataTaxRef:[],
        formTaxReference: {
          id: 2,
          inputs: {
            disabled: false,
            validate: "required",
            name: "sequence",
            placeholder: "Type to search",
            type: "multiselect",
            value: {},
            option: [],
            multiple: false,
            internal_search:false,
            allowempty: false,
            track_by: "ID",
            onSelectEvent: (selected) => {
              console.log(selected);
            },
          },
        },
        formAllocationType: {
          id: 3,
          inputs: {
            disabled: false,
            validate: "required",
            name: "allocation_type",
            placeholder: "Type to search",
            type: "multiselect",
            value: {
              ID: 2,
              text: "Manual",
            },
            option: [
              {
                ID: 0,
                text: "None",
              },
              {
                ID: 1,
                text: "Automate",
              },
              {
                ID: 2,
                text: "Manual",
              },
            ],
            multiple: false,
            allowempty: false,
            track_by: "ID",
            onSelectEvent: (selected) => {
              console.log(selected);
            },
          },
        },
        formInputTaxEntity: {
          id: 1,
          inputs: {
            disabled: true,
            validate: "required",
            name: "tax_entity",
            placeholder: "Tax Entity",
            type: "input",
            value: "",
          },
        },
        formInputYear: {
          id: 1,
          inputs: {
            disabled: false,
            validate: "required",
            name: "year",
            placeholder: "Year",
            type: "date",
            min_view:"year",
            format: 'yyyy',
            multiple: false,
            allowempty: false,
            value:new Date(),
          },
        },
        formInputFirstSn: {
          id: 1,
          inputs: {
            disabled: true,
            validate: "required",
            name: "first_sn",
            placeholder: "First Sn",
            type: "input",
            value: "",
          },
        },
        formInputLastSn: {
          id: 1,
          inputs: {
            disabled: true,
            validate: "required",
            name: "last_sn",
            placeholder: "Last Sn",
            type: "input",
            value: "",
          },
        },
        formInputTotalSn: {
          id: 1,
          inputs: {
            disabled: true,
            validate: "required",
            name: "total_sn",
            placeholder: "Total Sn",
            type: "input",
            value: "",
          },
        },
        formInputTotalSnUsed: {
          id: 1,
          inputs: {
            disabled: true,
            validate: "required",
            name: "total_sn_used",
            placeholder: "Total Sn Used",
            type: "input",
            value: "",
          },
        },
        formInputTotalSnRemaining: {
          id: 1,
          inputs: {
            disabled: true,
            validate: "required",
            name: "total_sn_ramaining",
            placeholder: "Total Sn Remaining",
            type: "input",
            value: "",
          },
        },
        formInputNpwp: {
          id: 1,
          inputs: {
            disabled: true,
            validate: "required",
            name: "npwp",
            placeholder: "NPWP",
            type: "input",
            value: "",
          },
        },
        formInputAddress: {
          id: 1,
          inputs: {
            disabled: true,
            validate: "required",
            name: "address",
            placeholder: "Address",
            type: "textarea",
            value: "",
          },
        },
        itemRefs: [
          {
            id: 0,
            state: "",
            action: false,
            inputs: this.getInputs(),
          },
        ],
        mdlitemRefs: {
          id: 0,
          state: "",
          action: false,
          inputs: this.getInputs(),
        },
        hisItemRefs: [
          {
            id: 1,
            state: "",
            action: false,
            inputs: this.getInputs2(),
          },
        ],
        mdlHisitemRefs: {
          id: 1,
          state: "",
          action: false,
          inputs: this.getInputs2(),
        },
        isvalidateFormInputTaxNumber:true,
        msgvalidateFormInputTaxNumber:"",
        tax_number: [],
        dt_tax_allocated: [],
        dtTaxNumberFirst: {},
        dtTaxNumberLast: {},
        dataFilter: [],
        tabs:0,
        colorx:"success",
        dataOu: {},
        dataOuId: [],
      };
    },
    handleChangelength(item){
      // console.log(item)
      if(item=="All"){
        this.table.end = this.table.total
      }else{
        this.table.end = item
      }
      this.table.length =this.table.end
      // this.getData()
    },
    handleSearch(search){
      this.table.page = 1
      this.table.search =search
      if (this.tab==this.tab_alloc){
        this.getData()
      }else if(this.tab==this.tab_data_alloc){
        this.getDataSnAllocation()
      }
    },
    handleChangePage(page) {
      if (this.tab==this.tab_alloc){
        this.table.page = page;
        this.getData()
      }else if(this.tab==this.tab_data_alloc){
        this.table.page = page;
        this.getDataSnAllocation()
      }
    },
    validateFormInputTaxNumber(data) {
      let statusValidate = false;
      let valueValidate = "";
      let msgValidate = "";
      // console.log(data);
      const iStartNumber = 3
        const iEndNumber = 4
      // this.itemRefs.forEach((dt, i) => {
      //   if(data){
      //     if (data.id != dt.inputs[iStartNumber].components.id) {
      //       const start_number = parseInt(dt.inputs[iStartNumber].components.value);
      //       const end_number = parseInt(dt.inputs[iEndNumber].components.value);
      //       if (
      //         parseInt(data.value) >= start_number &&
      //         parseInt(data.value) <= end_number
      //       ) {
      //         statusValidate = true;
      //         valueValidate = data.value;
      //         this.itemRefs[i].state = "danger";
      //         this.itemRefs[data.id].state = "danger";
      //         msgValidate = "Number " + valueValidate + " is already in use";
      //         return false;
      //       } else {
      //         this.itemRefs[i].state = "";
      //         this.itemRefs[data.id].state = "";
      //       }
      //     }
      //   }
      // });
      Object.keys(this.temp).forEach((key) => {
          if(data){
          if (data.id_ou != key) {
            const start_number = parseInt(this.temp[key].startNumber);
            const end_number = parseInt(this.temp[key].endNumber);
            if (
              parseInt(data.value) >= start_number &&
              parseInt(data.value) <= end_number
            ) {
              statusValidate = true;
              valueValidate = data.value;
              // this.itemRefs[i].state = "danger";
              this.itemRefs[data.id].state = "danger";
              msgValidate = "Number " + valueValidate + " is already in use";
              return false;
            } else {
              // this.itemRefs[i].state = "";
              this.itemRefs[data.id].state = "";
            }
          }
        }
      })
      if (!statusValidate) {

        // this.itemRefs.forEach((dt) => {
        //   const tax_number_allo = parseInt(dt.TaxNumber);
        //   if(data){
        //     if (parseInt(data.value) == tax_number_allo) {
        //       statusValidate = true;
        //       valueValidate = data.value;
        //       this.itemRefs[data.id].state = "danger";
        //       msgValidate = "Number " + valueValidate + " is already in use";
        //       return false;
        //     } else {
        //       this.itemRefs[data.id].state = "";
        //     }
        //   }
        // });
        // Object.keys(this.temp).forEach((key) => {

        // })
      }
      if (statusValidate) {
        this.$vs.notify({
          color: "warning",
          title: "Warning",
          text: msgValidate,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
      return !statusValidate
    },
    validateFormInputEmpty(){
      this.formTaxReference.inputs.value
      // console.log(this.formTaxReference)
      if(this.formTaxReference.inputs.value==undefined){
        this.$vs.notify({
          color: "warning",
          title: "Warning",
          text: "Sequence is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false
      }
      return true
    },
    getInputs(data = null, i = 0,no =0) {
      var inputs = [];
      var operating_unit =""
      var npwp =""
      var operating_unit_id = 0
      var startNumber = ""
      var endNumber = ""
      var newSN = ""
      if (data) {
        operating_unit = data.Code + " - " + data.Name
        npwp = data.Npwp
        operating_unit_id = data.ID
        console.log(this.temp)
        console.log(this.temp[data.ID])
        if (this.temp[data.ID]) {
          startNumber = this.temp[data.ID].startNumber
          endNumber = this.temp[data.ID].endNumber
          if (startNumber && endNumber) {
            newSN = parseInt(endNumber) - parseInt(startNumber) + 1
          }
        } else {
          this.temp[data.ID] = {
            startNumber: "",
            endNumber: "",
          }
        }
      }
      // if (data) {
      inputs.push({
        components: {
          id: i,
          id_input: -1,
          id_ou: operating_unit_id,
          name: "no",
          title: "No",
          placeholder: "No",
          type: "text",
          value: no,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 0,
          id_ou: operating_unit_id,
          disabled: true,
          validate: "required",
          name: "operating_unit",
          title: "Operating Unit",
          placeholder: "Operating Unit",
          type: "text",
          value: operating_unit,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 1,
          disabled: true,
          validate: "required",
          name: "npwp",
          placeholder: "NPWP",
          title: "NPWP",
          type: "text",
          value: npwp,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 2,
          id_ou: operating_unit_id,
          disabled: false,
          validate: "required",
          name: "start_number",
          placeholder: "Start Number",
          title: "Start Number",
          type: "input",
          value: startNumber,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 3,
          id_ou: operating_unit_id,
          disabled: false,
          validate: "required",
          name: "end_number",
          placeholder: "End Number",
          title: "End Number",
          type: "input",
          value: endNumber,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 4,
          disabled: true,
          validate: "required",
          name: "total_new_sn",
          placeholder: "Total New SN",
          title: "Total New SN",
          type: "input",
          value: newSN,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 5,
          disabled: true,
          validate: "required",
          name: "total_last_sn",
          placeholder: "Total Last SN",
          title: "Total Last SN",
          type: "input",
          value: "",
        },
      });
      // }
      return inputs;
    },
    getInputs2(data = null, i = 0,no=0) {
      var inputs = [];
      if (data) {
        inputs.push({
          components: {
            id: i,
            id_input: -1,
            id_ou: data.ID,
            disabled: true,
            validate: "required",
            name: "no",
            title: "No",
            placeholder: "No",
            type: "text",
            value: ""+no,
          },
        });
        inputs.push({
          components: {
            id: i,
            id_input: 0,
            id_ou: data.ID,
            disabled: true,
            validate: "required",
            name: "operating_unit",
            title: "Operating Unit",
            placeholder: "Operating Unit",
            type: "text",
            value: data.OuCode + " - " + data.OuName,
          },
        });
        inputs.push({
          components: {
            id: i,
            id_input: 1,
            disabled: true,
            validate: "required",
            name: "npwp",
            placeholder: "NPWP",
            title: "NPWP",
            type: "text",
            value: data.OuNpwp,
          },
        });
        inputs.push({
          components: {
            id: i,
            id_input: 2,
            disabled: true,
            validate: "required",
            name: "sequence",
            placeholder: "Sequence",
            title: "Sequence",
            type: "text",
            value: data.Sequence,
          },
        });
        inputs.push({
          components: {
            id: i,
            id_input: 2,
            disabled: true,
            validate: "required",
            name: "alocation",
            placeholder: "Allocation Type",
            title: "Allocation Type",
            type: "text",
            value: data.AlocationType,
          },
        });
        inputs.push({
          components: {
            id: i,
            id_input: 3,
            disabled: true,
            validate: "required",
            name: "start_number",
            placeholder: "Start Number",
            title: "Start Number",
            type: "text",
            // value: this.leadingZero(data.StartNumber, 12),
            value: this.leadingZero(data.StartNumber, 8),
          },
        });
        inputs.push({
          components: {
            id: i,
            id_input: 4,
            disabled: true,
            validate: "required",
            name: "end_number",
            placeholder: "End Number",
            title: "End Number",
            type: "text",
            // value: this.leadingZero(data.EndNumber, 12),
            value: this.leadingZero(data.EndNumber, 8),
          },
        });
        inputs.push({
          components: {
            id: i,
            id_input: 5,
            disabled: true,
            validate: "required",
            name: "total_sn",
            placeholder: "Total SN",
            title: "Total SN",
            type: "text",
            value: data.TotalAllocation,
          },
        });
      }else{
        inputs.push({
          components: {
            id: 0,
            id_input: 0,
            id_ou: 0,
            disabled: true,
            validate: "required",
            name: "no",
            title: "No",
            placeholder: "No",
            type: "text",
            value: 0,
          },
        });
        inputs.push({
          components: {
            id: 0,
            id_input: 0,
            disabled: true,
            validate: "required",
            name: "operating_unit",
            title: "Operating Unit",
            placeholder: "Operating Unit",
            type: "input",
            value:"",
          },
        });
        inputs.push({
          components: {
            id: 0,
            id_input: 1,
            disabled: true,
            validate: "required",
            name: "npwp",
            placeholder: "NPWP",
            title: "NPWP",
            type: "input",
            value: "",
          },
        });
        inputs.push({
          components: {
            id: 0,
            id_input: 2,
            disabled: true,
            validate: "required",
            name: "sequence",
            placeholder: "Sequence",
            title: "Sequence",
            type: "input",
            value: "",
          },
        });
        inputs.push({
          components: {
            id: 0,
            id_input: 2,
            disabled: true,
            validate: "required",
            name: "alocation",
            placeholder: "Allocation Type",
            title: "Allocation Type",
            type: "input",
            value: "",
          },
        });
        inputs.push({
          components: {
            id: 0,
            id_input: 3,
            disabled: true,
            validate: "required",
            name: "start_number",
            placeholder: "Start Number",
            title: "Start Number",
            type: "input",
            value: "",
          },
        });
        inputs.push({
          components: {
            id: 0,
            id_input: 4,
            disabled: true,
            validate: "required",
            name: "end_number",
            placeholder: "End Number",
            title: "End Number",
            type: "input",
            value: "",
          },
        });
        inputs.push({
          components: {
            id: 0,
            id_input: 5,
            disabled: true,
            validate: "required",
            name: "total_sn",
            placeholder: "Total SN",
            title: "Total SN",
            type: "input",
            value: "",
          },
        });
      }
      return inputs;
    },
    oninput(data, type) {
      if (type == "itemref") {
        var total_sn = 0;
        const iStartNumber = 3
        const iEndNumber = 4
        const iTotalNewNumber = 5
        if (data.name == "start_number") {
          console.log(data)
          console.log(this.temp)
          console.log(this.temp[data.id_ou])
          console.log(this.temp[data.id_ou].startNumber)
          this.temp[data.id_ou].startNumber = data.value
          if (data.value){
            total_sn =
            this.itemRefs[data.id].inputs[iEndNumber].components.value - parseInt(data.value) + 1;
          }else{
            total_sn =0
          }
          
            // console.log(total_sn)
          if (total_sn <= 0) {
            this.itemRefs[data.id].inputs[iTotalNewNumber].components.value = "" + 0;
          } else {
            this.itemRefs[data.id].inputs[iTotalNewNumber].components.value = "" + total_sn;
          }
          // this.inputLastTotalSn(this.total_sn_perou);
          this.inputLastTotalSn();
          this.isvalidateFormInputTaxNumber = this.validateFormInputTaxNumber(data);
          this.msgvalidateFormInputTaxNumber =!this.isvalidateFormInputTaxNumber?"SN cannot be the same":""
        } else if (data.name == "end_number") {
          this.temp[data.id_ou].endNumber = data.value
          total_sn =
            data.value - this.itemRefs[data.id].inputs[iStartNumber].components.value + 1;
          if (total_sn < 0) {
            this.itemRefs[data.id].inputs[iTotalNewNumber].components.value = "" + 0;
          } else {
            this.itemRefs[data.id].inputs[iTotalNewNumber].components.value = "" + total_sn;
          }
          // this.inputLastTotalSn(this.total_sn_perou);
          this.inputLastTotalSn();
          this.isvalidateFormInputTaxNumber = this.validateFormInputTaxNumber(data);
          this.msgvalidateFormInputTaxNumber =!this.isvalidateFormInputTaxNumber?"SN cannot be the same":""
        }
      }
    },
    oninputSearch() {
      this.getData;
    },
    oninputSearchData() {
      this.getDataSnAllocation();
    },
    handleSubmit() {
      if(!this.isvalidateFormInputTaxNumber){
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: this.msgvalidateFormInputTaxNumber,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
      let isValidTN =true
      // console.log(this.formInputTotalSnRemaining.inputs.value)
      // if (this.formAllocationType.inputs.value.ID!=0) {
        if(this.formInputTotalSnRemaining.inputs.value==0||this.formInputTotalSnRemaining.inputs.value=='0'||!this.formInputTotalSnRemaining.inputs.value){
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "tax number is not available",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          isValidTN=false
        }
      // }

      if(isValidTN&&this.isvalidateFormInputTaxNumber&&this.validateFormInputEmpty()&&this.inputLastTotalSn()){
        this.$vs.loading();
        // console.log(this.itemRefs);
        const params = {
          // taxref: JSON.stringify(this.formTaxReference.inputs.value),
          taxref: this.formTaxReference.inputs.value,
          // data: this.formAllocationType.inputs.value.ID!=1?JSON.stringify(this.itemRefs):"",
          data: Object.keys(this.temp)
            .filter(k => this.temp[k].startNumber && this.temp[k].endNumber)
            .map(k => ({id_ou: k, start_number: this.temp[k].startNumber, end_number: this.temp[k].endNumber})),
          allocationtype: this.formAllocationType.inputs.value.text,
          tax_year: this.formInputYear.inputs.value.getFullYear()+"",
        };
        this.$http
          .post(`api/v1/master/tax-invoice/allocation`, params)
          .then((resp) => {
            this.$vs.loading.close();
            // console.log(resp);
            if (resp.code == 500) {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else if (resp.code == 404) {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else if (resp.code == 200) {
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: "Data Saved",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
              this.resetFormInput();
              this.renderTable = false
              this.$nextTick(() => this.renderTable = true)
              this.getDataTaxNumber(this.formAllocationType.inputs.value);
            }else{
              this.$vs.notify({
                color: "danger",
                title: "failed to save data",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
        })
          .catch((err) => {
            this.$vs.loading.close();
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: err,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            console.log("error boss:", err);
        });
      }else{
        if (isValidTN){
          if (this.isvalidateFormInputTaxNumber&&this.msgvalidateFormInputTaxNumberInvalid!=""){
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: this.msgvalidateFormInputTaxNumberInvalid,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.msgvalidateFormInputTaxNumberInvalid = ""
          }
        }
      }
    },
    handleEdit() {},
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getData() {
      console.log("year", this.formInputYear.inputs.value)
      this.$vs.loading.close();
      this.$vs.loading();
      const params = {
        length: this.table.length,
        page: this.table.page,
        search: this.table.search,
        order: this.table.order,
        sort: this.table.sort,
        load_refs: true,
        tax_year: this.formInputYear.inputs.value.getFullYear()+"",
        // search: this.search,
      };
      // console.log(this.formInput)
      this.$http
        .get(`api/v1/master/tax-invoice`, { params })
        .then((resp) => {
          // console.log(this.option.event, 'option')
          console.log(resp);
          this.$vs.loading.close();
          var dataref = [];
          var dtou = [];
          if (resp.code == 500) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else if (resp.code == 200) {
            this.formInputTaxEntity.inputs.value = resp.data.company;
            this.formInputTaxEntity.inputs.disabled = true;
            this.formInputNpwp.inputs.value = resp.data.npwp;
            this.formInputNpwp.inputs.disabled = true;
            this.formInputAddress.inputs.value = resp.data.address;
            this.formInputAddress.inputs.disabled = true;
            dataref = resp.data.dataRef;

            this.table.total = resp.data.total_record
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.setStartEnd()
            if (dataref) {
              var tempDataref = []
              dataref.forEach((dt, i) => {
                tempDataref.push({
                  ID: i,
                  text: dt.Sequence,
                });
                // this.formTaxReference.inputs.option.push({
                //   ID: i,
                //   text: dt.Sequence,
                // });
              });
              // if(this.formTaxReference.inputs.option.length<=0){
                this.formTaxReference.inputs.option = tempDataref
                this.tempDataTaxRef = tempDataref
                if (!(this.formTaxReference.inputs.value && this.formTaxReference.inputs.value.ID)) {
                  this.formTaxReference.inputs.value =
                    this.formTaxReference.inputs.option[0];
                }
              // }
              if (dataref[0]) {
                if(this.formTaxReference.inputs.value){
                  var tempDataTaxNumber = dataref.filter((p)=>{
                    return p.Sequence == this.formTaxReference.inputs.value.text
                  })
                  if (tempDataTaxNumber.length>0) {
                    // this.formInputYear.inputs.value = tempDataTaxNumber[0].TaxYear;
                  }else{
                    // this.formInputYear.inputs.value = dataref[0].TaxYear;
                  }
                }else{
                  // this.formInputYear.inputs.value = dataref[0].TaxYear;
                }
                
              }
              // console.log("dataref:",dataref)
              this.getDataTaxNumber(this.formAllocationType.inputs.value);
            }
            this.dataOu = {}
            this.dataOuId = []
            resp.data.dtOuAll.forEach((dt, idx) => {
              this.dataOuId.push(dt.ID)
              this.dataOu[`${dt.ID}`] = {startNumber: 0, endNumber: 0, totalNew: 0, totalLast: 0}
            })
            dtou = resp.data.dtOu;
            if (dtou) {
              let dataInput = [];
              let no = this.table.start
              dtou.forEach((dt, i) => {
                dataInput.push({
                  id: i,
                  state: "",
                  action: false,
                  inputs: this.getInputs(dt, i,no),
                });
                no++
              });
              this.itemRefs = dataInput;
              this.inputLastTotalSn()
              this.mdlitemRefs =
                dataInput.length != 0
                  ? dataInput[0]
                  : { id: 0, state: "", action: false, inputs: this.getInputs() };
            }
            // console.log("this.itemRefs", this.itemRefs);
            // console.log("this.mdlitemRefs", this.mdlitemRefs);
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log("error boss:", err);
        });
    },
    getDataOu() {
      this.$vs.loading();
      const params = {
        // length: this.table.length,
        //   page: this.table.page,
        //   // search: this.table.search,
        //   order: this.table.order,
        //   sort: this.table.sort,
        search: this.search,
      };
      // console.log(this.formInput)
      this.$http
        .get(`api/v1/master/tax-invoice/operating-unit`, { params })
        .then((resp) => {
          // console.log(this.option.event, 'option')
          // console.log(resp);
          this.$vs.loading.close();
          var dtou = [];
          // this.table.total = resp.data.total_record
          //   this.table.totalPage = resp.data.total_page;
          //   this.table.totalSearch = resp.data.total_record_search;
          //   this.table.length = resp.data.total_record_per_page;
          if (resp.code == 500) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else if (resp.code == 200) {
            dtou = resp.data.dtOu;
            if (dtou) {
              let dataInput = [];
              dtou.forEach((dt, i) => {
                dataInput.push({
                  id: i,
                  state: "",
                  action: false,
                  inputs: this.getInputs(dt, i),
                });
              });
              this.itemRefs = dataInput;
            }
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log("error boss:", err);
        });
    },
    getDataSnAllocation() {
      var dtref = ""
      if(this.formTaxReference.inputs.value){
      dtref = this.formTaxReference.inputs.value.text
      }
      this.$vs.loading();
      const params = {
        length: this.table.length,
          page: this.table.page,
          search: this.table.search,
          order: this.table.order,
          sort: this.table.sort,
        // search: this.searchSn,
          // sequence : dtref
          year: this.formInputYear.inputs.value.getFullYear(),
      };
      // console.log(this.formInput)
      this.$http
        .get(`api/v1/master/tax-invoice/data-sn-allocation`, { params })
        .then((resp) => {

          
          // console.log(this.option.event, 'option')
          // console.log("resp",resp);
          this.$vs.loading.close();
          var dtsn = [];
          if (resp.code == 500) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else if (resp.code == 200) {
            dtsn = resp.data.dataSnAllocation;
            this.table.total = resp.data.total_record
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.setStartEnd()
            if (dtsn) {
              let dataInput = [];
              let no=this.table.start
              dtsn.forEach((dt, i) => {
                dataInput.push({
                  id: i,
                  state: "",
                  action: false,
                  inputs: this.getInputs2(dt, i,no),
                });
                no++
              });
            this.table.total = resp.data.total_record
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
              this.hisItemRefs = dataInput;
            }
            this.getDataTaxNumber(this.formAllocationType.inputs.value);
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log("error boss:", err);
        });
    },
    updateforminput(selected, component) {
      if (component.name == "sequence") {
        if (this.tab==this.tab_alloc){
          this.getData()
          this.getDataTaxNumber(this.formAllocationType.inputs.value);
          // this.resetFormInput();
        }else if(this.tab==this.tab_data_alloc){
          this.getDataSnAllocation()
        }
      } else if (component.name == "allocation_type") {
        if(this.tab==this.tab_alloc){
          if (selected.ID == 0) {
            this.noneAllocationType()
          } else {
            this.automateInputTaxNumber(selected);
          }
        }
      }
    },
    noneAllocationType() {
      this.itemRefs.forEach((_,i)=>{
        this.itemRefs[i].inputs[3].components.disabled =true
        this.itemRefs[i].inputs[4].components.disabled =true
      })
      this.resetFormInput();
    },
    automateInputTaxNumber(selected) {
      // this.tax_number = this.tax_number.filter((dt) => {
      //   var taxAllocateds = this.dt_tax_allocated.Data;
      //   taxAllocateds = taxAllocateds.filter((dtAllo) => {
      //     return dt.TaxNumber == dtAllo.TaxNumber;
      //   });

      //   return taxAllocateds.length == 0;
      // });
      // console.log("dt_tax_allocated", this.dt_tax_allocated.Data);
      // console.log("tax_number", this.tax_number);
      // let count_tax_number = this.tax_number.length;
      let count_tax_number = this.formInputTotalSnRemaining.inputs.value;
      // var count_ou = this.table.total;
      const dataOu = this.dataOuId
      var count_ou = dataOu.length;
      var count_item = this.itemRefs.length;
      // console.log("count_ou :",count_ou)
      // console.log("count_ou :",count_item)
      // count_ou = 5;
      let total_sn_perou = parseInt(Math.floor(count_tax_number / count_ou));
      if (total_sn_perou == 0) total_sn_perou = 1
      this.total_sn_perou = total_sn_perou
      // console.log("total_sn_perou :",total_sn_perou)
      let bonus = 0
      console.log(count_tax_number)
      console.log(total_sn_perou)
      console.log(count_ou)
      if ((total_sn_perou * count_ou) < count_tax_number) {
        bonus = count_tax_number - (total_sn_perou * count_ou)
      } 
      let item_i = 0;
      let total_new_sn = 0;
      let jml_sn_allo = 1
      if (selected) {
        const iStartNumber =3
        const iEndNumber =4
        const iTotalNewSN =5
        const iTotalLastSN =6
        if (selected.text == "Automate") {
          const from = parseInt(this.formInputFirstSn.inputs.value)
          dataOu.forEach((ou, idx) => {
            console.log(count_tax_number)
            if (count_tax_number == 0) return
            // let startIdx = total_sn_perou*idx
            // this.dataOu[ou].startNumber = this.tax_number[startIdx].TaxNumber
            if (bonus > idx) {
              this.dataOu[ou].startNumber = from + (idx * total_sn_perou) + idx
            } else {
              this.dataOu[ou].startNumber = from + (idx * total_sn_perou) + bonus
            }
            if (count_tax_number >= total_sn_perou) {
              let ni = idx + 1
              if (bonus > idx) {
                this.dataOu[ou].endNumber = from + (ni * total_sn_perou) - 1 + ni
                this.dataOu[ou].totalNew = total_sn_perou + 1
                count_tax_number -= 1
              } else {
                this.dataOu[ou].endNumber = from + (ni * total_sn_perou) - 1 + bonus
                this.dataOu[ou].totalNew = total_sn_perou
              }

              // this.dataOu[ou].endNumber = this.tax_number[startIdx+total_sn_perou-1].TaxNumber
              // this.dataOu[ou].endNumber = this.formInputFirstSn.inputs.value + ((idx + 1) * total_sn_perou) - 1
              // this.dataOu[ou].totalNew = total_sn_perou
              count_tax_number -= total_sn_perou
            } else {
              this.dataOu[ou].totalNew = count_tax_number
              // this.dataOu[ou].endNumber = this.tax_number[startIdx+count_tax_number-1].TaxNumber
              this.dataOu[ou].endNumber = from + ((idx + 1) * count_tax_number) - 1
              count_tax_number -= count_tax_number
            }
            this.dataOu[ou].totalLast = count_tax_number
          })
          this.resetFormInput();
          for (let row = 0; row < count_item; row++) {
            const idOu = this.itemRefs[row].inputs[0].components.id_ou
            this.itemRefs[row].inputs[iStartNumber].components.value = this.dataOu[idOu].startNumber != 0 ? this.leadingZero(this.dataOu[idOu].startNumber, 8) : ""
            this.itemRefs[row].inputs[iEndNumber].components.value = this.dataOu[idOu].endNumber != 0 ? this.leadingZero(this.dataOu[idOu].endNumber, 8) : ""
            this.itemRefs[row].inputs[iTotalNewSN].components.value = this.dataOu[idOu].totalNew != 0 ? this.dataOu[idOu].totalNew : ""
            this.itemRefs[row].inputs[iTotalLastSN].components.value = this.dataOu[idOu].totalNew != 0 ? this.dataOu[idOu].totalLast : ""
          }
          // this.tax_number.forEach((dt, i) => {
          //   this.tempTaxNumberAllocated.push(dt.TaxNumber)
          // })
          // this.tax_number.forEach((dt, i) => {
          //   // console.log(((this.table.start-1)*total_sn_perou),"<",i)
          //   if (((this.table.start-1)*total_sn_perou)<=i) {
          //     if (item_i <= count_item) {
          //       // alert(dt.TaxNumber);
          //       if (total_sn_perou==1) {
          //         jml_sn_allo=0
          //       }
          //       if (i<total_sn_perou*2+2) {
          //         console.log("index("+i+")",item_i,">>",jml_sn_allo,">>",total_sn_perou)
          //       }
          //       if(jml_sn_allo<total_sn_perou){
          //         if (total_sn_perou==1) {
          //           jml_sn_allo=1
          //         }
          //         if (jml_sn_allo==1){
          //           if(item_i < count_item){
          //             // this.itemRefs[item_i].inputs[iStartNumber].components.value = this.leadingZero(dt.TaxNumber, 12);
          //             this.itemRefs[item_i].inputs[iStartNumber].components.value = this.leadingZero(dt.TaxNumber, 8);
          //           }
          //           item_i++
          //         }
          //         jml_sn_allo++
          //       }else{
          //         jml_sn_allo = 1
          //       }
          //       if(item_i <= count_item){
          //         // console.log("item_i - 1:",item_i - 1)
          //         total_new_sn =
          //         dt.TaxNumber -
          //         this.itemRefs[item_i - 1<0?0:item_i - 1].inputs[iStartNumber].components.value +
          //         1;
          //         // this.itemRefs[item_i - 1<0?0:item_i - 1].inputs[iEndNumber].components.value = this.leadingZero(dt.TaxNumber, 12);
          //         this.itemRefs[item_i - 1<0?0:item_i - 1].inputs[iEndNumber].components.value = this.leadingZero(dt.TaxNumber, 8);
          //         this.itemRefs[item_i - 1<0?0:item_i - 1].inputs[iTotalNewSN].components.value =
          //         "" + total_new_sn;
          //       }
  
          //       // alert(true);
          //     }
          //   }
          // });

          // console.log("this.itemRefs:",this.itemRefs)
          // console.log("this.tempTaxNumberAllocated:",this.tempTaxNumberAllocated)
          this.itemRefs.forEach((_,i)=>{
            this.itemRefs[i].inputs[iStartNumber].components.disabled =true
            this.itemRefs[i].inputs[iEndNumber].components.disabled =true
          })
          // this.inputLastTotalSn(this.total_sn_perou);
        } else {
          this.itemRefs.forEach((dt,i)=>{
            this.itemRefs[i].inputs[iStartNumber].components.disabled =false
            this.itemRefs[i].inputs[iEndNumber].components.disabled =false
          })
          this.resetFormInput();
        }
      } else {
        this.resetFormInput();
      }
    },
    resetFormInput() {
      this.itemRefs.forEach((_, i) => {
        // alert(dt.TaxNumber);
        this.itemRefs[i].inputs[3].components.value = "";
        this.itemRefs[i].inputs[4].components.value = "";
        this.itemRefs[i].inputs[5].components.value = "";
        this.itemRefs[i].inputs[6].components.value = "";
      });
      this.temp = {}
    },
    handlerSearchMS(comp,search){
      if (comp.name =="sequence"){
        this.formTaxReference.inputs.option =this.tempDataTaxRef.filter(v=>{
          var tempText = v.text.toLowerCase()
          var tempSearch = search.toLowerCase()
          return tempText.search(tempSearch)>=0
        })
      }
    },
    inputLastTotalSn(total_sn_perou =0) {
      const currentPage = this.itemRefs.map((d) => d.inputs[0].components.id_ou)
      const totalExceptCurrent = Object.keys(this.temp)
        .filter((k) => !currentPage.includes(parseInt(k)))
        .reduce((total, k) => {
          const s = parseInt(this.temp[k].endNumber || "0")
          const e = parseInt(this.temp[k].endNumber || "0")
          return s && e ? total + s - e + 1 : total
        }, 0)
      // let total = 0;
      let total_remaining = parseInt(
        this.formInputTotalSnRemaining.inputs.value - ((this.table.start-1)*total_sn_perou) - totalExceptCurrent
      );
      const start_number =3
      const total_new_sn =5
      const total_last_sn =6
      const end_number =4
      var stsTotal = true
      var stsTotalInvalid = true
      var msg =""
      this.itemRefs.forEach((_, i) => {
        // alert(dt.TaxNumber);
        if (this.itemRefs[i].inputs[start_number].components.value != "") {
          total_remaining -= parseInt(
            this.itemRefs[i].inputs[total_new_sn].components.value
          );
          this.itemRefs[i].inputs[total_last_sn].components.value = "" + (total_remaining>=0?total_remaining:0);
          if (stsTotal) {
            stsTotal = total_remaining>=0  
            if (!stsTotal) {
              msg = "SN is not enough"
              
            }          
          }
        }else{
          this.itemRefs[i].inputs[total_last_sn].components.value = "";
        }
        // console.log("this.itemRefs[i].inputs["+start_number+"].components.value",this.itemRefs[i].inputs[start_number].components.value)
        // console.log("this.itemRefs[i].inputs["+end_number+"].components.value",this.itemRefs[i].inputs[end_number].components.value)
        // console.log(parseInt(this.itemRefs[i].inputs[start_number].components.value)>parseInt(this.itemRefs[i].inputs[end_number].components.value))

        if (parseInt(this.itemRefs[i].inputs[start_number].components.value)>parseInt(this.itemRefs[i].inputs[end_number].components.value)) {
          if (stsTotal&&stsTotalInvalid) {
              this.msgvalidateFormInputTaxNumberInvalid = "Cannot save, SN invalid!!"
              stsTotalInvalid =false
            }
        }
      });
      
      if(!stsTotal){
        this.$vs.notify({
          color: "danger",
          title: "Warning",
          text:msg,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
      
      return stsTotal&&stsTotalInvalid
    },
    getDataTaxNumber(selected = null) {
      // console.log("year", this.formInputYear.inputs.value)
      this.$vs.loading();
      const params = {
        taxref: this.formTaxReference.inputs.value
          ? this.formTaxReference.inputs.value.text
          : "",
        tax_year: this.formInputYear.inputs.value.getFullYear()+"",
      };
      // console.log(this.formInput)
      this.$http
        .post(`api/v1/master/tax-invoice/tax-number`, params)
        .then((resp) => {
          // console.log("getDataTaxNumber", resp);
          // console.log(this.option.event, 'option')
          this.$vs.loading.close();
          if (resp.code == 500) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else if (resp.code == 200) {
            // console.log("//////////////////////////////")
            // console.log(resp.data)
            // if (resp.data.totalSnRemaining) {
            if (resp.data.totalUnallocated) {
              this.formInputTotalSnRemaining.inputs.value =
                // resp.data.totalSnRemaining;
                resp.data.totalUnallocated;
              this.formInputFirstSn.inputs.value = 
                // resp.data.dtTaxNumberFirst.TaxNumber;
                this.leadingZero(resp.data.dtTaxNumberFirst, 8);
              this.formInputLastSn.inputs.value = 
                // resp.data.dtTaxNumberLast.TaxNumber;
                this.leadingZero(resp.data.dtTaxNumberLast, 8);
            } else {
              this.formInputTotalSnRemaining.inputs.value = "" + 0;
              this.formInputFirstSn.inputs.value = "" + 0;
              this.formInputLastSn.inputs.value = "" + 0;
            }
            // this.formInputFirstSn.inputs.value = this.leadingZero(this.formInputFirstSn.inputs.value, 12)
            // this.formInputFirstSn.inputs.value = this.formInputFirstSn.inputs.value
            // this.formInputLastSn.inputs.value = this.leadingZero(this.formInputLastSn.inputs.value, 12)
            // this.formInputLastSn.inputs.value = this.formInputLastSn.inputs.value
            if (resp.data.totalSN) {
              this.formInputTotalSn.inputs.value =
                resp.data.totalSN;
            } else {
              this.formInputTotalSn.inputs.value = "" + 0;
            }
            if (resp.data.totalAllocated) {
              this.formInputTotalSnUsed.inputs.value =
                resp.data.totalAllocated;
            } else {
              this.formInputTotalSnUsed.inputs.value = "" + 0;
            } 
            if (resp.data.dtTaxNumber) {
              this.tax_number = resp.data.dtTaxNumber;
              
            }
            if (resp.data.dtTaxAllocated) {
              // const alocatedLen = resp.data.dtTaxAllocated.length
              // const alocationType = alocatedLen ? resp.data.dtTaxAllocated[alocatedLen-1].AlocationType : "None"
              this.dt_tax_allocated = resp.data.dtTaxAllocated;
              // this.formAllocationType.inputs.value = {
              //     ID: alocationType == "Manual" ? 2 : alocationType == "Automate" ? 1 : 0,
              //     text: alocationType,
              //   }
            }
            if (resp.data.dtTaxNumberFirst) {
              this.dtTaxNumberFirst = resp.data.dtTaxNumberFirst;
            }
            if (resp.data.dtTaxNumberLast) {
              this.dtTaxNumberLast = resp.data.dtTaxNumberLast;
            }
            // console.log(selected)
            if (selected.ID == 0) {
              this.noneAllocationType();
            }
            else if (selected.ID == 1) {
              // console.log(selected)
            //   this.automateInputTaxNumber(selected);
            // } else {
              this.automateInputTaxNumber(selected);
            }
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log("error boss:", err);
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    resetTable(){
      this.table.total =0
      this.table.totalPage =0
      this.table.totalSearch =0
      this.table.length =10
      this.table.start=0
      this.table.end=0
      this.table.search = ""
    },
    tabClick(i){
      this.resetTable()
      if(i==this.tab_alloc){
        this.getData()
        this.table.stripe = false
      }else if(i==this.tab_data_alloc){
        this.table.stripe = true
        this.getDataSnAllocation()
      }
      this.tab=i
    }
  },
};
</script>
<style>
.vs-input--icon {
  font-size: 1.5rem;
}
</style>