<template>
  <div class="vx-row" style="margin-top: 10px">
    <div class="vx-col md:w-1/1 w-full" style="margin-bottom: 160px">
      <vx-card title="Form Tax Invoice">
        <div class="vx-row mb-6 ml-4 mr-4" style="z-index: 99999 !important">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Tax Entity</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <formInputs :components="this.formInput.inputs" />
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>NPWP</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <formInputs :components="this.formNpwp.inputs" />
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Address</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <formInputs :components="this.formAddress.inputs" />
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Year</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <formInputs :components="this.formYear.inputs" @update-forminput="this.updateforminput" />
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-4/5 w-full text-center">
            <vs-button
              v-if="tabs == 1"
              class="mr-4"
              @click="handleView()"
              color="primary"
              icon-pack="feather"
              >View
            </vs-button>
          </div>
        </div>
        <vs-tabs :color="colorx" v-model="tabs">
          <vs-tab
            @click="
              colorx = 'success';
              tabClick(0);
            "
            label="SN Register"
          >
            <div class="con-tab-ejemplo" :style="{'height': height+'px','overflow-y':'auto'}">
              <datatable
                :itemRefs="this.itemRefs"
                :modelItemRefInput="this.itemRefs[0]"
                @update-itemref="updateitemref"
                @input-itemref="oninput"
                @date-pickerOpened="datepickerOpened"
                @date-pickerClosed="datepickerClosed"
              />
            </div>
          </vs-tab>
          <vs-tab
            @click="
              colorx = 'warning';
              tabClick(1);
            "
            label="Data Sn"
          >
            <div class="con-tab-ejemplo">
              <vs-row class="mb-6">
                <vs-col
                  class="px-5 mb-3"
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="center"
                  vs-w="6"
                >
                <!-- <vs-dropdown vs-trigger-click class="cursor-pointer">
                  <div
                    class="
                      p-3 border border-solid
                      d-theme-border-grey-light
                      rounded-full d-theme-dark-bg
                      cursor-pointer flex items-center 
                      justify-between font-medium"
                  >
                    <span class="mr-2"
                      >{{ this.table.start }} - {{ this.table.end }} of
                      {{ this.table.total }}</span
                    >
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>
                  <vs-dropdown-menu>
                    <vs-dropdown-item
                      v-for="item in table.limits"
                      :key="item"
                      @click="handleChangelength(item)"
                    >
                      <span>{{ item }}</span>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown> -->
                </vs-col>
                <!-- <vs-col
                  class="px-5 mb-3"
                  vs-type="flex"
                  vs-justify="right"
                  vs-align="center"
                  vs-w="6"
                >
                  <vs-input
                    icon="search"
                    placeholder="Search"
                    v-model="table.search"
                    @input="oninputSearch"
                  />
                </vs-col> -->
                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="12"
                >
                  <datatable_v2
                  class="w-full"
                    :itemRefs="this.hisItemRefs"
                    :tablep="this.table"
                    :modelItemRefInput="this.hisItemRefsMdl"
                    @update-itemref="updateitemref"
                    @input-itemref="oninput"
                    @change-length ="handleChangelength"
                    @search-itemref ="handleSearch"
                    @handleChangePage="handleChangePage"
                    @date-pickerOpened="datepickerOpened"
                    @date-pickerClosed="datepickerClosed"
                    @click="onclick"
                  />
                </vs-col>
              </vs-row>
              
            </div>
          </vs-tab>
        </vs-tabs>

        <!-- div-parameter -->
        <vs-row class="mb-5" vs-justify="left">
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="1">
            <vs-button
              v-show="btn_show"
              @click="handleSubmit()"
              color="primary"
              icon-pack="feather"
              >Submit
            </vs-button>
          </vs-col>
        </vs-row>
      </vx-card>
    </div>
  </div>
</template>

<script>
import datatable from "./datatable2.vue";
import datatable_v2 from "./datatable.vue";
import formInputs from "./form_inputs.vue";
import moment from "moment";
export default {
  components: {
    datatable,
    formInputs,
    datatable_v2,
  },
  data() {
    return {
      selected: {},
      responseData: {},
      users: [],
      type: 0,
      table:{
        start:1,
        stripe:false,
        end : 0,
        page: 0,
        length: 10,
        model :{},
        search: "",
        order: "id",
        sst: true,
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        
      },
      data: {
        npwp: "",
      },
      tabs :0,
      height:230,
      height_def:180,
      colorx:"success",
      iAction: 0,
      iTaxRef :1,
      // iStartDate :2,
      // iEndDate :3,
      iPrefix :2,
      iStartNumber :3,
      iEndNumber :4,
      iTotalSN :5,
      iTaxAllocated :6,
      iTaxUnallocated :7,
      // iTaxRemaining :7,
      iTaxUsed :8,
      // iTaxRemaining :8,
      iTaxRemaining :9,
      formTaxEntity: {
        id: 1,
        inputs: {
          disabled: false,
          validate: "required",
          name: "taxt_entity",
          placeholder: "Type to search",
          type: "multiselect",
          value: {},
          option: [],
          multiple: false,
          allowempty: false,
          track_by: "ID",
          onSelectEvent: (selected) => {
            console.log(selected);
          },
        },
      },
      formInput: {
        id: 1,
        inputs: {
          disabled: false,
          validate: "required",
          name: "tax_entity",
          placeholder: "Tax Entity",
          type: "input",
          value: "",
        },
      },
      formYear: {
        id: 1,
        inputs: this.forminputyear(),
      },
      formAddress: {
        id: 1,
        inputs: {
          disabled: false,
          validate: "required",
          name: "address",
          placeholder: "Address",
          type: "textarea",
          value: "",
        },
      },
      formNpwp: {
        id: 1,
        inputs: {
          disabled: false,
          validate: "required",
          name: "npwp",
          placeholder: "NPWP",
          type: "input",
          value: "",
        },
      },
      itemRefs: [
        {
          id: 1,
          state:"",
          action: true,
          inputs: this.getInputs(),
        },
      ],
      hisItemRefs: [],
      hisItemRefsMdl: { 
        id: 2,
        action: false,
        inputs: this.getInputs1(),
      },
      option_table: {},
      btn_show: true,
      validateTotalSn:{
        status :true,
        message:""
      },
      search:"",
    };
  },
  mounted() {
    console.log("Router: ", this.$router);
    this.getData();
  },
  methods: {
    handleView() {
      this.getDataRef()
    },
    forminputyear() {
      
      return {
        disabled: false,
        validate: "required",
        name: "year",
        placeholder: "Select Year",
        type: "date",
        min_view:"year",
        format: 'yyyy',
        multiple: false,
        allowempty: false,
        value:new Date().getFullYear()+"",
        track_by: "ID",
        onSelectEvent: (selected) => {
          console.log(selected);
        },
      };
    },
    updateforminput(q){
      console.log(q)
      if (this.tabs == 0) {
        const date = new Date(Date.parse(q)), y = date.getFullYear()
        this.resetItemRef(y)
      }
      // this.getDataRef()
    },
    onclick(e) {
      const dt = e.data
      console.log("dt", dt)
      // if (dt.TotalSn == dt.TaxRemaining || dt.TaxAllocated == 0) {
      if (dt.TotalSn == dt.Remaining || dt.Used == 0) {
        // this.deleteSN(dt.TaxYear, dt.TaxReference)
        // this.deleteSN(dt.ID)
        this.confirmDelete(dt.ID)
      } else {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Cannot delete an already allocated SN",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    confirmDelete(id) {
      this.$vs.dialog({
        type: "confirm",
        color: "warning",
        title: "Delete Confirmation",
        text: "Are you sure to delete this Serial Number?",
        accept: () => this.deleteSN(id),
        "accept-text": "Yes",
      })
    },
    // deleteSN(year, reference) {
    deleteSN(id) {
      this.$vs.loading();
      const params = {
        // year: year,
        // reference: reference
        id,
      }
      this.$http
        .delete(`api/v1/master/tax-invoice/delete`,{params})
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.getDataRef()
            
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        });
    },
    oninput(data, type) {
      var numb = this.getNumber(data.value)
      if (type == "itemref") {
        var total_sn = 0;
        var sn =0
        var en =0
        if (data.name == "start_number") {
          total_sn =
            // this.itemRefs[data.id].inputs[5].components.value - numb+1;
            this.itemRefs[data.id].inputs[3].components.value - numb+1;
          if (total_sn < 0) {
            // this.itemRefs[data.id].inputs[6].components.value = "" + 0;
            this.itemRefs[data.id].inputs[4].components.value = "" + 0;
            // this.itemRefs[data.id].inputs[7].components.value = "" + 0;
            // this.itemRefs[data.id].inputs[8].components.value = "" + 0;
          } else {
            // this.itemRefs[data.id].inputs[6].components.value = "" + total_sn;
            this.itemRefs[data.id].inputs[4].components.value = "" + total_sn;
            // this.itemRefs[data.id].inputs[7].components.value = "" + total_sn;
            // this.itemRefs[data.id].inputs[8].components.value = "" + total_sn;
          }
          if (total_sn<=0) {
            this.itemRefs[data.id].state = "danger"
              this.validateTotalSn.status = false
              this.validateTotalSn.message = "end number cannot be less than start number"
          }else{
            // sn = this.itemRefs[data.id].inputs[4].components.value
            sn = this.itemRefs[data.id].inputs[2].components.value
            // en = this.itemRefs[data.id].inputs[5].components.value
            en = this.itemRefs[data.id].inputs[3].components.value
            if((parseInt(sn)+parseInt(en))==0||parseInt(sn)==0){
              this.itemRefs[data.id].state = "danger"
              this.validateTotalSn.status = false
              this.validateTotalSn.message = "Invalid SN"
            }else{
              this.itemRefs[data.id].state = ""
              this.validateTotalSn.status = true
              this.validateTotalSn.message = ""
            }
          }
        } else if (data.name == "end_number") {
          total_sn =
          // numb - this.itemRefs[data.id].inputs[4].components.value+1;
          numb - this.itemRefs[data.id].inputs[2].components.value+1;
          if (total_sn < 0) {
            // this.itemRefs[data.id].inputs[6].components.value = "" + 0;
            this.itemRefs[data.id].inputs[4].components.value = "" + 0;
            // this.itemRefs[data.id].inputs[7].components.value = "" + 0;
            // this.itemRefs[data.id].inputs[8].components.value = "" + 0;
          } else {
            // this.itemRefs[data.id].inputs[6].components.value = "" + total_sn;
            this.itemRefs[data.id].inputs[4].components.value = "" + total_sn;
            // this.itemRefs[data.id].inputs[7].components.value = "" + total_sn;
            // this.itemRefs[data.id].inputs[8].components.value = "" + total_sn;
          }
          if (total_sn<=0) {
            this.itemRefs[data.id].state = "danger"
              this.validateTotalSn.status = false
              this.validateTotalSn.message = "end number cannot be less than start number"
          }else{
            // sn = this.itemRefs[data.id].inputs[4].components.value
            sn = this.itemRefs[data.id].inputs[2].components.value
            // en = this.itemRefs[data.id].inputs[5].components.value
            en = this.itemRefs[data.id].inputs[3].components.value
            if((parseInt(sn)+parseInt(en))==0||parseInt(sn)==0){
              this.itemRefs[data.id].state = "danger"
              this.validateTotalSn.status = false
              this.validateTotalSn.message = "Invalid SN"
            }else{
              this.itemRefs[data.id].state = ""
              this.validateTotalSn.status = true
              this.validateTotalSn.message = ""
            }
          }
        }
        
      }
    },
    getNumber(txt=""){
      var str = 0+txt
      var numb = str.replace(/[^0-9]/g, '');
      return parseInt(numb)
    },
    oninputSearch(search){
      console.log(search)
      this.getDataRef()
    },
    updateitemref(data, status) {
      if (status == "add") {
        let i_new = this.itemRefs.length;
        this.height = this.height_def+(60*i_new)
        this.itemRefs.push(data);
        for (let i = 0; i < this.itemRefs[i_new].inputs.length; i++) {
          this.itemRefs[i_new].inputs[i].components.id = i_new;
          // if (/*i != 0 &&*/ i != 1 && i != 2) {
            this.itemRefs[i_new].inputs[i].components.value = "";
          // } else if (i == 1 || i == 2) {
            // this.itemRefs[i_new].inputs[i].components.value =
            //   this.itemRefs[i_new - 1].inputs[i].components.value;
            // this.itemRefs[i_new].inputs[i].components.disabled_dates.to =
            //   new Date(Date.parse(this.itemRefs[i_new - 1].inputs[i].components.disabled_dates.to));
            // this.itemRefs[i_new].inputs[i].components.disabled_dates.from =
            //   new Date(Date.parse(this.itemRefs[i_new - 1].inputs[i].components.disabled_dates.from));
          // }
        }
      } else if (status == "remove") {
        this.itemRefs = this.itemRefs.filter((v, i) => {
          return i != data;
        });
        this.height = this.height_def+(60*this.itemRefs.length)
      }
    },
    getInputs(year) {
      var inputs = [];
      const date = new Date(), m = date.getMonth(),d=date.getDate();
      var y = date.getFullYear()
      if (year) {
        y = year
      }
      var today = new Date(y, m, d);
      var lastDay = new Date(y+1, 0, 0);
      console.log("today",today.getFullYear())
      console.log("lastDay",lastDay)
      var startDate = today;
      var endDate = lastDay;
      // var startDate = new Date(
      //   today.getFullYear(),
      //   today.getMonth(),
      //   today.getDay()
      // );
      // var endDate = new Date(
      //   today.getFullYear(),
      //   today.getMonth(),
      //   today.getDay() + 1
      // );

      inputs.push({
        components: {
          id: 0,
          id_input: 0,
          disabled: false,
          validate: "required",
          name: "sequence",
          placeholder: "Sequence",
          title: "Sequence",
          type: "input",
          value: "",
        },
      });
      // inputs.push({
      //   components: {
      //     id: 0,
      //     id_input: 1,
      //     disabled: false,
      //     validate: "required",
      //     name: "start_date",
      //     placeholder: "Start Date",
      //     title: "Start Date",
      //     type: "date",
      //     value: startDate,
      //     disabled_dates: {
      //       to: new Date(y, 0,1),
      //       from: new Date(y+1, 0,0),
      //     },
      //   },
      // });
      // inputs.push({
      //   components: {
      //     id: 0,
      //     id_input: 2,
      //     disabled: false,
      //     validate: "required",
      //     name: "end_date",
      //     placeholder: "End Date",
      //     title: "End Date",
      //     type: "date",
      //     value: endDate,
      //     disabled_dates: {
      //       to: new Date(y, 0,1),
      //       from: new Date(y+1, 0,0),
      //     },
      //   },
      // });
      inputs.push({
        components: {
          id: 0,
          id_input: 3,
          disabled: false,
          validate: "required",
          name: "prefix",
          placeholder: "Prefix",
          title: "Prefix",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 4,
          disabled: false,
          validate: "required",
          name: "start_number",
          placeholder: "Start Number",
          title: "Start Number",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 5,
          disabled: false,
          validate: "required",
          name: "end_number",
          placeholder: "End Number",
          title: "End Number",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 6,
          disabled: true,
          validate: "required",
          name: "total_sn",
          placeholder: "Total SN",
          title: "Total SN",
          type: "input",
          value: "",
        },
      });
      // inputs.push({
      //   components: {
      //     id: 0,
      //     id_input: 7,
      //     disabled: true,
      //     validate: "required",
      //     name: "total_allocation",
      //     placeholder: "Total Allocation",
      //     title: "Total Allocation",
      //     type: "input",
      //     value: "",
      //   },
      // });
      // inputs.push({
      //   components: {
      //     id: 0,
      //     id_input: 8,
      //     disabled: true,
      //     validate: "required",
      //     name: "total_remaining",
      //     placeholder: "Total Remaining",
      //     title: "Total Remaining",
      //     type: "input",
      //     value: "",
      //   },
      // });
      return inputs;
    },
    getInputs1() {
      var inputs = [];
      // const today = new Date();
      // var startDate = new Date(
      //   today.getFullYear(),
      //   today.getMonth(),
      //   today.getDay()
      // );
      // var endDate = new Date(
      //   today.getFullYear(),
      //   today.getMonth(),
      //   today.getDay() + 1
      // );

      inputs.push({
        components: {
          id: 0,
          id_input: 0,
          disabled: false,
          validate: "",
          name: "action",
          placeholder: "Action",
          title: "Action",
          type: "button",
          icon: "icon-trash",
          color: "danger",
          text: "Delete",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 0,
          disabled: true,
          validate: "",
          name: "sequence",
          placeholder: "Sequence",
          title: "Sequence",
          type: "text",
          value: "",
        },
      });
      // inputs.push({
      //   components: {
      //     id: 0,
      //     id_input: 1,
      //     disabled: true,
      //     validate: "",
      //     name: "start_date",
      //     placeholder: "Start Date",
      //     title: "Start Date",
      //     type: "text",
      //     value: startDate,
      //   },
      // });
      // inputs.push({
      //   components: {
      //     id: 0,
      //     id_input: 2,
      //     disabled: true,
      //     validate: "",
      //     name: "end_date",
      //     placeholder: "End Date",
      //     title: "End Date",
      //     type: "text",
      //     value: endDate,
      //   },
      // });
      inputs.push({
        components: {
          id: 0,
          id_input: 3,
          disabled: true,
          validate: "",
          name: "prefix",
          placeholder: "Prefix",
          title: "Prefix",
          type: "text",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 4,
          disabled: true,
          validate: "",
          name: "start_number",
          placeholder: "Start Number",
          title: "Start Number",
          type: "text",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 5,
          disabled: true,
          validate: "",
          name: "end_number",
          placeholder: "End Number",
          title: "End Number",
          type: "text",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 6,
          disabled: true,
          validate: "",
          name: "total_sn",
          placeholder: "Total SN",
          title: "Total SN",
          type: "text",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 7,
          disabled: true,
          validate: "",
          name: "total_allocation",
          placeholder: "Total Allocation",
          title: "Total Allocation",
          type: "text",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 7,
          disabled: true,
          validate: "",
          name: "total_unallocation",
          placeholder: "Total Unallocated",
          title: "Total Unallocated",
          type: "text",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 8,
          disabled: true,
          validate: "",
          name: "total_used",
          placeholder: "Total Used",
          title: "Total Used",
          type: "text",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 8,
          disabled: true,
          validate: "",
          name: "total_remaining",
          placeholder: "Total Remaining",
          title: "Total Remaining",
          type: "text",
          value: "",
        },
      });
      return inputs;
    },
    getIDItemRef() {
      return 0;
    },
    resetItemRef(year) {
      this.itemRefs = [
        {
          id: 1,
          action: true,
          inputs: this.getInputs(year),
        },
      ];
    },
    handleSubmit() {
      var validateEmpty = true
      var validateEmptyMsg = ""
      // var totalSN = 0
      let tempPrefix = {}
      this.itemRefs.forEach((dt,i)=>{
        // totalSN += parseInt(dt.inputs[6].components.value)
        // if (i && parseInt(dt.inputs[4].components.value) <= parseInt(this.itemRefs[i-1].inputs[5].components.value)) {
        // if (i && parseInt(dt.inputs[2].components.value) <= parseInt(this.itemRefs[i-1].inputs[3].components.value)) {
        //   validateEmpty = false
        //   validateEmptyMsg = "Start number must be greater than previuos end number"
        //   return
        // }
        if (i && parseInt(dt.inputs[0].components.value) - parseInt(this.itemRefs[i-1].inputs[0].components.value) != 1) {
          validateEmpty = false
          validateEmptyMsg = "Sequence must be sequential"
          return
        }
        // if (this.itemRefs.slice(0, i).some((ir) => ir.inputs[0].components.value == this.itemRefs[i].inputs[0].components.value)) {
        //   validateEmpty = false
        //   validateEmptyMsg = this.itemRefs[i].inputs[0].components.title +" must be unique"
        //   return
        // }
        // if (dt.inputs[0].components.value.length != 3) {
        if (!dt.inputs[0].components.value) {
          validateEmpty = false
          // validateEmptyMsg = dt.inputs[0].components.title +" must exactly 3 characters"
          validateEmptyMsg = dt.inputs[0].components.title +" is required"
          return
        }
        if (!dt.inputs[1].components.value) {
          validateEmpty = false
          // validateEmptyMsg = dt.inputs[3].components.title +" must exactly 3 characters"
          validateEmptyMsg = dt.inputs[1].components.title +" is required"
          return
        }
        if (dt.inputs[1].components.value.length != 3) {
          validateEmpty = false
          validateEmptyMsg = dt.inputs[1].components.title +" must exactly 3 characters"
          return
        }
        let prep = tempPrefix[dt.inputs[1].components.value]
        let prepEnd = prep ? prep.end : 0
        if (prepEnd >= parseInt(dt.inputs[2].components.value)) {
          validateEmpty = false
          validateEmptyMsg = "Start number must be greater than previuos end number in same prefix"
          return
        }
        tempPrefix[dt.inputs[1].components.value] = {
          // start: parseInt(dt.inputs[2].components.value),
          end: parseInt(dt.inputs[3].components.value),
        }

        // if (dt.inputs[4].components.value.length != 8) {
        //   validateEmpty = false
        //   validateEmptyMsg = dt.inputs[4].components.title +" must exactly 8 characters"
        //   return
        // }
        // if (dt.inputs[5].components.value.length != 8) {
        //   validateEmpty = false
        //   validateEmptyMsg = dt.inputs[5].components.title +" must exactly 8 characters"
        //   return
        // }
        this.itemRefs[i].inputs.forEach((dt2,i2)=>{
          if (this.itemRefs[i].inputs[i2].components.validate=="required"&&validateEmpty) {
            if(
              this.itemRefs[i].inputs[i2].components.value==""||
              this.itemRefs[i].inputs[i2].components.value ==null||
              this.itemRefs[i].inputs[i2].components.value==undefined
              ){
                validateEmpty = false
                validateEmptyMsg = this.itemRefs[i].inputs[i2].components.title +" is "+this.itemRefs[i].inputs[i2].components.validate
                return
            }
          }
          // var dtdate = moment(this.itemRefs[i].inputs[1].components.value).format('YYYY-MM-DD')
          // console.log("dtdate1",dtdate)
          // this.itemRefs[i].inputs[1].components.value = dtdate
          // // var start_d =dtdate
          // // var start_d = new Date(dtdate)
          // dtdate = moment(this.itemRefs[i].inputs[2].components.value).format('YYYY-MM-DD')
          // this.itemRefs[i].inputs[2].components.value = dtdate
          // console.log("dtdate2",dtdate)
          // var end_d = dtdate
          // var end_d = new Date(dtdate)
        })
      })
      // if (totalSN > 1000) {
      //   validateEmpty = false
      //   validateEmptyMsg = "Max total SN generated in one request is 1000"
      // }
      console.log("this.itemRefs",this.itemRefs)
      if (this.validateTotalSn.status&&validateEmpty) {
        this.$vs.loading();
        console.log(this.itemRefs);
        var year ="2022"
        if (this.formYear.inputs.value) {
          year =moment(this.formYear.inputs.value).format("YYYY")
        }
        const sn = this.itemRefs.map(i => ({
          sequence: i.inputs[0].components.value,
          // start_date: i.inputs[1].components.value,
          // end_date: i.inputs[2].components.value,
          // prefix: i.inputs[3].components.value,
          prefix: i.inputs[1].components.value,
          // start_number: parseInt(i.inputs[4].components.value),
          start_number: parseInt(i.inputs[2].components.value),
          // end_number: parseInt(i.inputs[5].components.value),
          end_number: parseInt(i.inputs[3].components.value),
          // totalSn: i.inputs[0].components.value,
        }))
        const params = {
          year,
          sn,
        };
        console.log(params)
        this.$http
          .post(`api/v1/master/tax-invoice/create`, params)
          .then((resp) => {
            this.$vs.loading.close();
            console.log(resp);
            if (resp.code == 500) {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else if (resp.code == 200) {
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: "Data Saved",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
              this.resetItemRef();
            } else if (resp.code == 400) {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
        })
          .catch((err) => {
            this.$vs.loading.close();
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: err,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            console.log("error boss:", err);
        }); 
      }else{
        var msg = ""
        if(!validateEmpty){
          msg = validateEmptyMsg
        }else if(!this.validateTotalSn.status){
          msg = this.validateTotalSn.message
        }

        this.$vs.notify({
            color: "danger",
            title: "Error",
            text: msg,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
      }
    },
    handleEdit() {},
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getData() {
      this.$vs.loading();
      // const params = {
      //   id: this.create.id,
      // }
      // console.log(this.formInput)
      this.$http
        .get(`api/v1/master/tax-invoice`)
        .then((resp) => {
          console.log("resp",resp)
          // console.log(this.option.event, 'option')
          this.$vs.loading.close();
          if (resp.code == 500) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else if (resp.code == 200) {
            this.formInput.inputs.value = resp.data.company;
            this.formInput.inputs.disabled = true;
            this.formNpwp.inputs.value = resp.data.npwp;
            this.formNpwp.inputs.disabled = true;
            this.formAddress.inputs.value = resp.data.address;
            this.formAddress.inputs.disabled = true;
            this.formYear.inputs.value = new Date();
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log("error boss:", err);
        });
    },
    leadingZero(value, fixLength) {
      return "0".repeat(fixLength-value.toString().length)+value
    },
    getDataRef() {
      this.$vs.loading();
      var year = ""
      if(this.formYear.inputs.value){
        year = moment(this.formYear.inputs.value).format("YYYY")
      }
      console.log(this.formYear.inputs)
      const params = {
        length: this.table.length,
        page: this.table.page,
        search: this.table.search,
        order: this.table.order,
        sort: this.table.sort,
        year: year,
      }
      // console.log(this.formInput)
      this.$http
        .get(`api/v1/master/data-reference`,{params})
        .then((resp) => {
          console.log("resp",resp)
          
          // console.log(this.option.event, 'option')
          this.$vs.loading.close();
          if (resp.code == 500) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else if (resp.code == 200) {
            this.table.total = resp.data.total_record
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;

            this.hisItemRefs = []
            resp.data.records.forEach((dt,i)=>{
              var dataItem = { 
                id: i,
                action: false,
                inputs: this.getInputs1(),
              }
              dataItem.inputs[this.iAction].components.data = dt
              // dataItem.inputs[this.iTaxRef].components.value = dt.TaxReference
              dataItem.inputs[this.iTaxRef].components.value = dt.Sequence
              // dataItem.inputs[this.iStartDate].components.value = moment(dt.StartDate).format('YYYY-MM-DD')
              // dataItem.inputs[this.iEndDate].components.value = moment(dt.EndDate).format('YYYY-MM-DD')
              // dataItem.inputs[this.iPrefix].components.value = dt.Prefix
              dataItem.inputs[this.iPrefix].components.value = this.leadingZero(dt.Prefix, 3)
              // dataItem.inputs[this.iStartNumber].components.value = this.leadingZero(dt.StartNumber, 12)
              dataItem.inputs[this.iStartNumber].components.value = this.leadingZero(dt.StartNumber, 8)
              // dataItem.inputs[this.iEndNumber].components.value = this.leadingZero( dt.EndNumber, 12)
              dataItem.inputs[this.iEndNumber].components.value = this.leadingZero( dt.EndNumber, 8)
              dataItem.inputs[this.iTotalSN].components.value = dt.TotalSn
              // dataItem.inputs[this.iTaxAllocated].components.value = dt.TaxAllocated
              // dataItem.inputs[this.iTaxAllocated].components.value = dt.Used
              dataItem.inputs[this.iTaxAllocated].components.value = dt.Allocated
              dataItem.inputs[this.iTaxUnallocated].components.value = dt.Unallocated
              // dataItem.inputs[this.iTaxRemaining].components.value = dt.TaxRemaining
              dataItem.inputs[this.iTaxUsed].components.value = dt.Used
              dataItem.inputs[this.iTaxRemaining].components.value = dt.Remaining
              this.hisItemRefs.push(dataItem)
              this.setStartEnd()
            })
            if (this.hisItemRefs.length>0) {
              this.model = this.hisItemRefs[0]
            }
            console.log("model",this.model)

          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log("error boss:", err);
        });
    },
    datepickerOpened(components){
      var countData = this.itemRefs.length
      // console.log(components)
      // if (countData==1) {
      //   this.height = 260
      //   // this.height = 244
      // }
      // if (countData==2) {
      //   this.height = 210
      //   // this.height = 244
      // }
      // if (countData==3) {
      //   this.height = 160
      //   // this.height = 244
      // }
      // if (countData==4) {
      //   this.height = 130
      //   // this.height = 244
      // }
      // if (countData==5) {
      //   this.height = 60
      //   // this.height = 244
      // }
      if (countData<=3) {
        this.height = this.height_def+(70*countData)
        // console.log(this.height)
      }

      // if (components.id==5) {
      //   this.height = 260
      // }
      // if (components.id==4) {
      //   this.height = 220
      // }
      // if (components.id==3) {
      //   this.height = 150
      // }
      // if (components.id==2) {
      //   this.height = 100
      // }
      // if (components.id==1) {
      //   this.height = 50
      // }
    },
    datepickerClosed(){
      // var countData = this.itemRefs.length
      // this.height = this.height_def+(60*countData)
      // console.log(this.height)
    },
    handleChangelength(item){
      console.log(item)
      if(item=="All"){
        this.table.end = this.table.total
      }else{
        this.table.end = item
      }
      this.table.length =this.table.end
      this.getDataRef()
    },
    handleSearch(search){
      this.table.search =search
      this.getDataRef()
    },
    handleChangePage(page){
      // if (this.tab==this.tab_reg){
        this.table.page = page;
        this.getDataRef()
      // }
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    tabClick(i) {
      this.table.search = ""
      if (i == 0) {
        this.btn_show = true;
        this.table.stripe=false
      } else {
        this.btn_show = false;
        this.table.stripe=true
        // this.getDataRef()
      }
    },
  },
};
</script>